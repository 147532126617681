import React, { useEffect, useState } from 'react'
import "./GrossPayCalculator.scss"
import { Form } from 'react-bootstrap'
import { CalculatorViewApi } from '../../../Constants/Api/Api'
const GrossPayCalculator = (calculatordetails) => {
    const [hourly_cost, setHourlyCost] = useState("")
    const [number_of_days, setNumberoFDays] = useState("")
    const [hours_per_day, setHoursPerDay] = useState("")
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    const storedValue = localStorage.getItem("UserLoginTokenApt");
    const handleView = () => {
        CalculatorViewApi(calculatordetails?.calculatordetails?.id, storedValue).then((res) => {
        }).catch((error) => console.log(error, "error"))
    }
    return (
        <div className='GrossPayCalculator'>
            <div className='Row_1'>
                <div className='Col_1 blank_input'>Enter your hourly cost
                    <div className='Col_12'>
                        <Form.Control placeholder='Enter value'
                            value={hourly_cost}
                            onChange={(e) => setHourlyCost(e.target.value)}
                            onClick={handleView}
                        /></div>
                </div>
            </div>
            <div className='Row_1'>
                <div className='Col_1 blank_input'>Enter Number of Days
                    <div className='Col_12'>
                        <Form.Control placeholder='Enter value '
                            value={number_of_days}
                            onChange={(e) => setNumberoFDays(e.target.value)}
                        /></div>
                </div>
            </div>
            <div className='Row_1'>
                <div className='Col_1 blank_input'>Enter Working hours per day
                    <div className='Col_12'>
                        <Form.Control placeholder='Enter value'
                            value={hours_per_day}
                            onChange={(e) => setHoursPerDay(e.target.value)}
                        /></div>
                </div>
            </div>
            <div className='Row_1'>
                <div className='Col_1 blank_input'>Total Amount :
                    <div className='Col_12'>
                        <div className='blank_input'>${hourly_cost * number_of_days * hours_per_day}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GrossPayCalculator
