import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./OtpStep.scss";
const SendOtp = ({ formData, setFormData }) => {
  const [isValidEmail, setIsValidEmail] = useState(true);

  const checkEmailFormat = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(formData.email);
  };

  return (
    <>
      <div className="SendOtp">
        <div className="SendOtp_Container">
          <Form style={{ textAlign: "left" }}>
            <Form.Group className="input_field">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                  setIsValidEmail(checkEmailFormat(e.target.value));
                }}
              />
              {!isValidEmail && (
                <p className="error-text">
                  Please enter a valid email address.
                </p>
              )}
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
};
export default SendOtp;
