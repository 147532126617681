
function calculateMarkupPercentage(cost, revenue) {
    let profit = revenue - cost;
    let markupPercentage = (profit / cost) * 100;
    return markupPercentage;
};
function calculateGrossMarginPercentage(cost, revenue) {
    let profit = revenue - cost;
    let grossMarginPercentage = (profit / revenue) * 100;
    return grossMarginPercentage;
};
function calculateGrossProfit(cost, revenue) {
    let grossProfit = revenue - cost;
    return grossProfit;
};

export function marginCalculator(cost, revenue) {

    if (!cost > 0 || !revenue >0 ) {
        return
    };
    cost = parseFloat(cost);
    revenue = parseFloat(revenue);

    // let markupPercentage = calculateMarkupPercentage(cost, revenue);
    // let grossMarginPercentage = calculateGrossMarginPercentage(cost, revenue);
    // let grossProfit = calculateGrossProfit(cost, revenue);

    if (isNaN(cost) || isNaN(revenue)) {
    } else {

        let markupPercentage = calculateMarkupPercentage(cost, revenue);
        let grossMarginPercentage = calculateGrossMarginPercentage(cost, revenue);
        let grossProfit = calculateGrossProfit(cost, revenue);

        let response = {
            "M": markupPercentage.toFixed(2),
            "G": grossMarginPercentage.toFixed(2),
            "P": grossProfit.toFixed(2)
        }

       
        return response
    };
};

// let cost = 100;
// let revenue = 50;
// marginCalculator(cost, revenue);