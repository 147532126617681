import React, { useEffect, useState } from 'react'
import "./HomeBanner.scss"
import BammerVideo from "../../assets/aptvideo.mp4";
import { GetBanner } from '../../Constants/Api/Api';

const HomeBanner = (props) => {
    const[value,setValue]=useState()
    useEffect(() => {
        GetBanner().then((res) => {
            setValue(res.data?.data?.[0])
        }).catch((e) => {
            console.log(e, "error")
        })
    },[])
    return (
        <div className="HomeBanner">
            <div className="section1">
                <div className="left">
                    <h3>{value?.heading}</h3>
                    <p dangerouslySetInnerHTML={{__html:value?.banner_content}}>
                    </p>
                </div>
                <div className="right">
                    <video autoPlay loop muted className='videoo'>
                        <source src={BammerVideo} autoPlay loop muted />
                    </video>
                    {/* <img src={props.CalculatorImage === undefined ? BannerPic : `${props.CalculatorImage}`} width={100} height={100} alt='Banner_img' className="picture" /> */}
                </div>
            </div>
        </div>
    )
}
export default HomeBanner
