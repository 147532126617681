
function calculateDiscountedPrice(listPrice, percentageOff) {
    let decimalPercent = percentageOff / 100;
    let discountAmount = listPrice * decimalPercent;
    let discountedPrice = listPrice - discountAmount;
    return discountedPrice;
};

function calculateMultiItemDiscount(listPrice, numberOfItemsAtListPrice, numberOfItemsInDeal) {
    let totalCost = numberOfItemsAtListPrice * listPrice;
    let discountedPricePerItem = totalCost / numberOfItemsInDeal;
    return discountedPricePerItem;
};

function calculateFractionOf(listPrice, fraction) {
    let salePrice = listPrice * fraction;
    return listPrice-salePrice;
};

export function salePriceCalculator(listPrice, percentageOff, priceOfMultiItemCal, numberOfItemsAtListPrice, numberOfItemsInDeal, priceOfFractionCal, fraction) {
    let discountedPrice = calculateDiscountedPrice(listPrice, percentageOff);


    let discountedPricePerItem = calculateMultiItemDiscount(priceOfMultiItemCal, numberOfItemsAtListPrice, numberOfItemsInDeal);

    let salePrice = calculateFractionOf(priceOfFractionCal, fraction);
    
    let response = {
        "discountedPrice":discountedPrice.toFixed(2),
        "discountedPricePerItem":discountedPricePerItem.toFixed(2),
        "salePrice":salePrice.toFixed(2)
    }
    return response

};
