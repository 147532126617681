import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SendOtp from "./SendOtp";
import VerifyOtp from "./VerifyOtp";
import { Button, Container } from "react-bootstrap";
import CreateAccount from "./CreateAccount";
import { Link } from "react-router-dom";
import DescriptionAlerts from "../../Constants/alert/alert";
import { SendOTPAPI, VerifyOtpAPI } from "../../Constants/Api/Api";
import "./OtpStep.scss";
export default function OtpStep() {
  const steps = ["Select campaign settings", "Create an ad group", "test"];
  const [alert, setAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
  });
  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const handleVerifyOTP = () => {
    VerifyOtpAPI(formData.email, formData.otp)
      .then((res) => {
        setAlert(true);
        setAlertConfig({
          text: "OTP has been verified. Please Create Your Profile.",
          icon: "success",
        });
        setTimeout(() => {
          // navigate("/signup")
          const newCompleted = completed;
          newCompleted[activeStep] = true;
          setCompleted(newCompleted);
          handleNext();
        }, 2000);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendOTP = () => {
    setAlert(false);
    const email = formData.email;
    if (!isEmailValid(email)) {
      setAlert(true);
      setAlertConfig({
        text: "Invalid email format. Please enter a valid email address.",
        icon: "error",
      });
      return;
    }
    SendOTPAPI(formData.email)
      .then((res) => {

        setAlert(true);
        setAlertConfig({
          text: "OTP has been Sent To Your Email",
          icon: "success",
        });
        setTimeout(() => {
          const newCompleted = completed;
          newCompleted[activeStep] = true;
          setCompleted(newCompleted);
          handleNext();
        }, 2000);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <>
      {alert ? (
        <DescriptionAlerts text={alertConfig.text} icon={alertConfig.icon} />
      ) : null}
      <div className="Otp">
        <div className="Main">
          <div className="Left"></div>

          <div className="Right">
            {allStepsCompleted() ? (
              <React.Fragment>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h3>Signup</h3>
                <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                  {activeStep === 0 && (
                    <SendOtp formData={formData} setFormData={setFormData} />
                  )}
                  {activeStep === 1 && (
                    <VerifyOtp formData={formData} setFormData={setFormData} />
                  )}
                  {activeStep === 2 && (
                    <CreateAccount
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <>
                    {completedSteps() === totalSteps() - 3 ? (
                      <div className="procced-btn">
                        <Button
                          onClick={handleSendOTP}
                          className="forgot_form_button button_theme"
                          style={{ marginLeft: "20px", marginRight: "20px" }}
                          disabled={!formData.email}
                          // size="sm"
                        >
                          Send Otp
                        </Button>
                      </div>
                    ) : completedSteps() === totalSteps() - 2 ? (
                      <div className="next-btn">
                        <Button
                          onClick={handleVerifyOTP}
                          className="forgot_form_button button_theme"
                          style={{ marginLeft: "20px", marginRight: "20px" }}
                          // size="sm"
                          disabled={!formData.otp}
                        >
                          Verify Otp
                        </Button>
                      </div>
                    ) : (
                      <div className="reset-btn"></div>
                    )}
                  </>
                </Box>
                <p className="otp_text">
                  Already have an account?
                  <span>
                    <Link to="/signin" className="signInLink_text">
                      {" "}
                      Sign in
                    </Link>
                  </span>{" "}
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
