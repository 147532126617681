import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Button, Modal, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { UserLogOutAPI } from '../../Constants/Api/Api';
import { getClinetProfile } from '../redux/getClientProfileSlice';
import { useDispatch } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import { FaFirstdraft } from "react-icons/fa";
import "./NavSection.scss"
const SubNav = ({ handleClosesidebar }) => {
  const [profile, setProfile] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const storedValue = localStorage.getItem("UserLoginTokenApt");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(getClinetProfile(storedValue)).then((res) => {
      setProfile(res?.payload)
    }).catch((error) => {
      console.log(error)
    })
    if (profile?.user_status === "DEACTIVATE") {
      handleLogout()
    }
  }, [dispatch, storedValue])
  const handleLogout = () => {
    localStorage.removeItem("UserLoginTokenApt");
    localStorage.removeItem("UserStatus");
    localStorage.removeItem("is_active");
    navigate("/")
    UserLogOutAPI(storedValue).then((res) => {
      // if (res?.status === 200) {
      // }
    }).catch((error) => {
      console.log(error)
    })
    // localStorage.removeItem("UserLoginTokenApt");
  }
  const [show, setShow] = useState(false);
  const handleClosealert = () => setShow(false);
  const handleShowalert = () => setShow(true);
  const UserStatus = localStorage.getItem("UserStatus")
  return (
    <>
      <Nav className=" justify-content-end ">
        <Nav.Link onClick={() => { navigate("/"); handleClosesidebar() }}>
          Home</Nav.Link>
        <Nav.Link onClick={() => { navigate("/calculator/hot-deals-calculator"); handleClosesidebar() }}>
          HOT</Nav.Link>
        <Nav.Link onClick={() => { navigate("/proposals"); handleClosesidebar() }}>
          Proposals</Nav.Link>
        <NavDropdown
          title="Calculators"
          id={`offcanvasNavbarDropdown-expand-md`}
        >
          <NavDropdown.Item onClick={() => { handleClosesidebar(); navigate("/calculator/advanced-price-exhibit") }}>
            Advanced Price Exhibit
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => { handleClosesidebar(); navigate("/calculator/margin-calculator") }}>
            Margin Calculator
          </NavDropdown.Item>
          {/* <NavDropdown.Item style={{ background: UserStatus === "ACTIVATE TRIAL" ? "#D3D3D3" : null, color: UserStatus === "ACTIVATE TRIAL" ? "#A8A8A8" : null, margin: "2px 0px" }} onClick={() =>
             { UserStatus === "ACTIVATE TRIAL" ? handleShowalert() : handleClosesidebar(); UserStatus === "ACTIVATE" && navigate("/calculator/profit-margin-calculator") }}>
            Profit Margin Calculator
          </NavDropdown.Item> */}
          <NavDropdown.Item
  style={{
    background: UserStatus === "ACTIVATE TRIAL" ? "#D3D3D3" : null,
    color: UserStatus === "ACTIVATE TRIAL" ? "#A8A8A8" : null,
    margin: "2px 0px"
  }}
  onClick={() => {
    if (UserStatus === "ACTIVATE TRIAL") {
      handleShowalert();
    } else {
      handleClosesidebar();
      navigate("/calculator/profit-margin-calculator");
    }
  }}
>
  Profit Margin Calculator
</NavDropdown.Item>

<NavDropdown.Item 
  style={{
    background: UserStatus === "ACTIVATE TRIAL" ? "#D3D3D3" : null,
    color: UserStatus === "ACTIVATE TRIAL" ? "#A8A8A8" : null,
    margin: "2px 0px"
  }}
  onClick={() => {
    if (UserStatus === "ACTIVATE TRIAL") {
      handleShowalert();
    } else {
      handleClosesidebar();
      navigate("/calculator/price-calculator");
    }
  }}
>
  Price Calculator
</NavDropdown.Item>

<NavDropdown.Item onClick={() => { handleClosesidebar(); navigate("/calculator/selling-price-calculator") }}>
  Selling Price Calculator
</NavDropdown.Item>

<NavDropdown.Item
  style={{
    background: UserStatus === "ACTIVATE TRIAL" ? "#D3D3D3" : null,
    color: UserStatus === "ACTIVATE TRIAL" ? "#A8A8A8" : null,
    margin: "2px 0px"
  }}
  onClick={() => {
    if (UserStatus === "ACTIVATE TRIAL") {
      handleShowalert();
    } else {
      handleClosesidebar();
      navigate("/calculator/sale-price-calculator");
    }
  }}
>
  Sale Price Calculator
</NavDropdown.Item>

<NavDropdown.Item onClick={() => { handleClosesidebar(); navigate("/calculator/gross-pay-calculator") }}>
  Gross Pay Calculator
</NavDropdown.Item>

<NavDropdown.Item 
  style={{
    background: UserStatus === "ACTIVATE TRIAL" ? "#D3D3D3" : null,
    color: UserStatus === "ACTIVATE TRIAL" ? "#A8A8A8" : null,
    margin: "2px 0px"
  }}
  onClick={() => {
    if (UserStatus === "ACTIVATE TRIAL") {
      handleShowalert();
    } else {
      handleClosesidebar();
      navigate("/calculator/partnership-pricing-volume-discounts");
    }
  }}
>
  Partnership Pricing
</NavDropdown.Item>

<NavDropdown.Item 
  style={{
    background: UserStatus === "ACTIVATE TRIAL" ? "#D3D3D3" : null,
    color: UserStatus === "ACTIVATE TRIAL" ? "#A8A8A8" : null,
    margin: "2px 0px"
  }}
  onClick={() => {
    if (UserStatus === "ACTIVATE TRIAL") {
      handleShowalert();
    } else {
      handleClosesidebar();
      navigate("/calculator/profitability-module");
    }
  }}
>
  Profitability Module
</NavDropdown.Item>

        </NavDropdown>
        {storedValue && (UserStatus !== "DEACTIVATE" || UserStatus === "ACTIVATE TRIAL" || UserStatus === "ACTIVATE") && (
          <div className='profile_icon'>
            <Tooltip title="Account settings"  >
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                style={{ margin: 0 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }} style={{ textTransform: "capitalize" }}>{profile?.name.charAt(0)}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => { navigate("/profile-edit"); handleClose() }}>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
              <MenuItem onClick={() => { navigate("/draft"); handleClose() }}>
                <ListItemIcon>
                  <FaFirstdraft fontSize="small" />
                </ListItemIcon>
                Draft
              </MenuItem>
              <MenuItem onClick={() => { handleLogout(); handleClose() }}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        )}
        {!storedValue && (UserStatus === "DEACTIVATE" || UserStatus === "ACTIVATE TRIAL" || UserStatus === "ACTIVATE" || UserStatus !== "DEACTIVATE") && (
          <Button style={{
            borderRadius: "10px",
            background: "#C8232C", border: "none"
          }}
            onClick={() => { navigate("/signin"); handleClosesidebar() }}
          >Login</Button>
        )}
        {/* {UserStatus==="DEACTIVATE" &&
          <Button style={{
            borderRadius: "10px",
            background: "#C8232C", border: "none"
          }}
            onClick={() => { navigate("/signin"); handleClosesidebar() }}
          >Login</Button>
        } */}
      </Nav>
      <Modal show={show}
        onHide={handleClosealert} centered>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need to subscribe to access this feature.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Link to="https://transactions.sendowl.com/subscriptions/30610/F24F8A1E/view" className="link_text">
            <Button variant="secondary">
              Subscribe to create an account
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default SubNav
