import React, { useEffect, useState } from 'react'
import "./ProfitabilityModule.scss"
import { Form } from 'react-bootstrap'
import { CalculatorViewApi } from '../../../Constants/Api/Api'
const ProfitabilityModule = (calculatordetails) => {
    const [sale, setSale] = useState("")
    const [purchase, setPurchase] = useState("")
    const [directCosts, setDirectCosts] = useState("")
    const [rent, setRent] = useState("")
    const [salary, setSalary] = useState("")
    const [generalExpenses, seGeneralExpenses] = useState("")
    const [depreciation, setDepreciation] = useState("")
    const [interestPaid, setInterestPaid] = useState("")
    const [taxes, setTaxes] = useState("")
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    },[])
    const storedValue = localStorage.getItem("UserLoginTokenApt");

    const handleView = () => {
        CalculatorViewApi(calculatordetails?.calculatordetails?.id,storedValue).then((res) => {
        }).catch((error) => console.log(error, "error"))
      }
    return (
        <div className='ProfitabilityModule'>
          
            <div className='Row_1'>
                    <div className='Col_1 blank_input'>Sale
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Sale'
                                value={sale}
                                onChange={(e) => setSale(e.target.value)}
                                onClick={handleView}
                            /></div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Purchase
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Purchase'
                                value={purchase}
                                onChange={(e) => setPurchase(e.target.value)}
                            /></div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Direct Costs
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Direct Costs'
                                value={directCosts}
                                onChange={(e) => setDirectCosts(e.target.value)}
                            /></div>
                    </div>
                </div> <div className='Row_1'>
                    <div className='Col_1 blank_input'>Rent
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Rent'
                                value={rent}
                                onChange={(e) => setRent(e.target.value)}
                            /></div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Salary
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Salary'
                                value={salary}
                                onChange={(e) => setSalary(e.target.value)}
                            /></div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'> General Expenses
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter General Expenses'
                                value={generalExpenses}
                                onChange={(e) => seGeneralExpenses(e.target.value)}
                            /></div>
                    </div>
                </div>                 <div className='Row_1'>
                    <div className='Col_1 blank_input'>Depreciation
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Depreciation'
                                value={depreciation}
                                onChange={(e) => setDepreciation(e.target.value)}
                            /></div>
                    </div>
                </div>                 <div className='Row_1'>
                    <div className='Col_1 blank_input'>Interest Paid
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Interest Paid'
                                value={interestPaid}
                                onChange={(e) => setInterestPaid(e.target.value)}
                            /></div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Taxes @ 30%
                        <div className='Col_12'>
                            <Form.Control placeholder='Enter Taxes @ 30%'
                                value={taxes}
                                onChange={(e) => setTaxes(e.target.value)}
                            /></div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Profit:
                        <div className='Col_12'>
                            <div className='blank_input'>${(sale-purchase-directCosts-rent-salary-generalExpenses-depreciation-interestPaid-taxes ).toFixed(2)}</div>
                        </div>
                    </div>
                </div><div className='Row_1'>
                    <div className='Col_1 blank_input'>Profitability:
                        <div className='Col_12'>
                            <div className='blank_input'>{((((sale-purchase-directCosts-rent-salary-generalExpenses-depreciation-interestPaid-taxes )/sale)*100)).toFixed(2) }%</div>
                        </div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Gross Profit:
                        <div className='Col_12'>
                            <div className='blank_input'>${(sale-purchase-directCosts).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Gross Profit Margin:
                        <div className='Col_12'>
                            <div className='blank_input'>{(((sale-purchase-directCosts)/sale)*100 ).toFixed(2)}%</div>
                        </div>
                    </div>
                </div> <div className='Row_1'>
                    <div className='Col_1 blank_input'>Operating Profit:
                        <div className='Col_12'>
                            <div className='blank_input'>${(sale-purchase-directCosts-rent-salary-generalExpenses-depreciation).toFixed(2) }</div>
                        </div>
                    </div>
                </div> 
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>Operating Profit Margin :
                        <div className='Col_12'>
                            <div className='blank_input'>{(((sale-purchase-directCosts-rent-salary-generalExpenses-depreciation)/sale)*100).toFixed(2) }%</div>
                        </div>
                    </div>
                </div>
                <div className='Row_1'>
                    <div className='Col_1 blank_input'>EBITDA :
                        <div className='Col_12'>
                            <div className='blank_input'>${(sale-purchase-directCosts-rent-salary-generalExpenses).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>
           
    )
}
export default ProfitabilityModule
