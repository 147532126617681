import React, { useEffect, useState } from 'react'
import "./SalePriceCalculator.scss"
import { Button, Form, Table } from 'react-bootstrap'
import { salePriceCalculator } from '../../../CalculatorFunction/saleCalculatorFunction'
import { CalculatorViewApi } from '../../../Constants/Api/Api'
const SalePriceCalculatorComponent = (calculatordetails) => {
  const [listPrice, SetListPrice] = useState()
  const [percentageOff, setPercentageOff] = useState()
  const [priceOfMultiItemCal, setPriceOfMultiItemCal] = useState()
  const [numberOfItemsAtListPrice, setNumberOfItemsAtListPrice] = useState(1)
  const [numberOfItemsInDeal, setNumberOfItemsInDeal] = useState(2)
  const [priceOfFractionCal, setPriceOfFractionCal] = useState()
  const [fraction, setFraction] = useState(0)
  const [result, setResult] = useState()
  const decimalToFraction = (fraction) => ({
    0: '0',
    0.25: '1/4',
    0.33: '1/3',
    0.5: '1/2',
    0.67: '2/3',
    0.75: '3/4'
  })[fraction % 1 === 0 ? fraction : parseFloat(fraction).toFixed(2)];
  const handleSubmit = async () => {
    try {
      let data = await salePriceCalculator(listPrice, percentageOff, priceOfMultiItemCal, numberOfItemsAtListPrice, numberOfItemsInDeal, priceOfFractionCal, fraction)
      setResult(data);
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  }
  const handleClear = () => {
    SetListPrice("")
    setPercentageOff("")
    setPriceOfMultiItemCal("")
    setNumberOfItemsAtListPrice(1)
    setNumberOfItemsInDeal(2)
    setPriceOfFractionCal("")
    setFraction(0)
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])
  const storedValue = localStorage.getItem("UserLoginTokenApt");

  const handleView = (id) => {
    CalculatorViewApi(id,storedValue).then((res) => {
    }).catch((error) => console.log(error, "error"))
  }
  return (
    <div className="SalePriceCalculatorComponent">

      <Form >
        <div className='cal_row'>
          <div className='cal_col_main'>
            <div className='cal_col'>
              <Form.Group className='mb-2' >
                <span className='add_placeholder_persent'>%</span>
                <Form.Control value={percentageOff} onChange={(e) => { setPercentageOff(e.target.value) }} placeholder='Enter % off' />
              </Form.Group>
            </div>
            <div className='cal_col_off'>Off</div>
          </div>
          <div className='cal_col_main'>
            <div className='cal_col'>
              <Form.Group className='mb-2'  >
                <span className='add_placeholder_doller'>$</span>
                <Form.Control value={listPrice} onChange={(e) => { SetListPrice(e.target.value) }} placeholder='Enter tagprice' title="positive real number" />
              </Form.Group>
            </div>
            <div className='cal_col_each'>each</div>
          </div>
        </div>
        <div className='cal_row'>
          <div className='cal_col_main'>
            <div className='cal_col'>
              <Form.Select value={fraction} onChange={(e) => { setFraction(e.target.value) }} >
                <option value={0}>0</option>
                <option value={1 / 4}>1/4</option>
                <option value={1 / 3}>1/3</option>
                <option value={1 / 2}>1/2</option>
                <option value={2 / 3}>2/3</option>
                <option value={3 / 4}>3/4</option>
              </Form.Select>
            </div>
            <div className='cal_col_off'>Off</div>
          </div>
          <div className='cal_col_main'>
            <div className='cal_col'>
              <Form.Group className='mb-2'  >
                <span className='add_placeholder_doller'>$</span>
                <Form.Control value={priceOfFractionCal} onChange={(e) => setPriceOfFractionCal(e.target.value)} placeholder='Enter tagprice' title="positive real number" />
              </Form.Group>
            </div>
            <div className='cal_col_each'>each</div>
          </div>
        </div>
        <div className='cal_row'>
          <div className='cal_col_main'>
            <div className='cal_col'>
              <div className='cal_col_row'>
                <div className='cal_col_col'>
                  <Form.Select value={numberOfItemsInDeal} onChange={(e) => { setNumberOfItemsInDeal(e.target.value) }} >
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </Form.Select>
                </div>
                <div className='cal_col_col'>
                  <Form.Select value={numberOfItemsAtListPrice} onChange={(e) => { setNumberOfItemsAtListPrice(e.target.value) }} >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className='cal_col_off'>Off</div>
          </div>
          <div className='cal_col_main'>
            <div className='cal_col'>
              <Form.Group className='mb-2'  >
                <span className='add_placeholder_doller'>$</span>
                <Form.Control value={priceOfMultiItemCal} onChange={(e) => { setPriceOfMultiItemCal(e.target.value) }} placeholder='Enter tagprice' title="positive real number" />
              </Form.Group>
            </div>
            <div className='cal_col_each'>each</div>
          </div>
        </div>
      </Form>
      <div className='Button_style'>
        <Button className="button_theme" onClick={handleClear}>Clear</Button>
        <Button className="button_theme" onClick={() => { handleSubmit(); handleView(calculatordetails?.calculatordetails?.id) }}>Calculate
        </Button>
      </div>
      {result &&
        <div className='Answer_Container'>
          <h5>Answer</h5>
          <Table bordered style={{ background: "white" }}>
            <thead style={{ background: "#F5F5F5" }}>
              <tr>
                <th>Discount</th>
                <th>Final Price Each</th>
              </tr>
            </thead>
            <tbody style={{ color: "#A0A0A0" }}>
              <tr>
                <td>{percentageOff} % off $ &nbsp;{listPrice}</td>
                <td>$&nbsp;{result?.discountedPrice}</td>
              </tr>
              <tr>
                <td>{decimalToFraction(fraction)} off $&nbsp;{priceOfFractionCal}</td>
                <td>$&nbsp;{result?.salePrice}</td>
              </tr>
              <tr>
                <td>{numberOfItemsInDeal} for {numberOfItemsAtListPrice} at $ &nbsp;{priceOfMultiItemCal}	</td>
                <td>$&nbsp;{result?.discountedPricePerItem}</td>
              </tr>
            </tbody>
          </Table>
        </div>}
    </div>


  )
}
export default SalePriceCalculatorComponent
