import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaFileDownload } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Url } from '../../Constants/host';
import { ProposalViewApi } from '../../Constants/Api/Api';
import { Link, useNavigate } from 'react-router-dom';

function CardComponent(props) {
    const [docSource, setDocSource] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleremove = () => {
        localStorage.removeItem("Calculation");
    }

    useEffect(() => {
        setTimeout(() => {
            const fetchedDocSource = `${Url}${props?.data?.file_type}/${props?.data?.file_name}`;
            setDocSource(fetchedDocSource);
        }, 2000);
    }, [props.data]);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = docSource;
        link.download = props.data.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClickView = (id) => {
        // ProposalViewApi(id).then((res) => {
        // }).catch((e) => {
        // })
    }

    const UserStatus = localStorage.getItem("UserStatus");

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubscribe = () => {
        navigate("/https://transactions.sendowl.com/subscriptions/30610/F24F8A1E/view");
    };

    return (
        <>
            <div className='card_component'>
                <div className="card mb-3 custom_card">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src="https://www.adobe.com/dc-shared/assets/images/frictionless/og-images/og-word-to-pdf.jpg" className="img-fluid rounded-start" alt="..." />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title" dangerouslySetInnerHTML={{ __html: props?.data?.title }}></h5>
                                {props?.data?.proposal_type === "EDITABLE" ?
                                    (UserStatus === "ACTIVATE TRIAL" ?
                                        <Button className='button_theme' onClick={() => setShowModal(true)}>Edit Template</Button> :
                                        <Button className='button_theme'
                                            onClick={() => {
                                                handleClickView(props?.data?.id); handleremove();
                                                navigate(`/create/${props?.data?.title === "Coaching Agreement Template" ? `coaching-agreement/${props?.data?.id}` : props?.data?.title === "Speaking Agreement Template" ? `speaking-agreement/${props?.data?.id}` : props?.data?.title === "Speaker Agreement Template" ? `speaker-agreement/${props?.data?.id}` : props?.data?.title === "Leadership Workshop Agreement Template" ? `leadership-workshop-proposal/${props?.data?.id}` : "test"}`);
                                            }}>Edit Template</Button>
                                    ) :
                                    <Button className='button_theme'>
                                        <div className='view_btn'>
                                            <div
                                                onClick={() => {
                                                    if (UserStatus === "ACTIVATE TRIAL") {
                                                        setShowModal(true);
                                                    } else {
                                                        navigate('/viewproposal', { state: { data: props.data } });
                                                        handleClickView(props?.data?.id);
                                                    }
                                                }}
                                            >
                                                View Template
                                            </div>
                                            {/* &nbsp; */}
                                            {UserStatus === "ACTIVATE" &&
                                                <div onClick={() => { handleDownload(); handleClickView(props?.data?.id) }}>
                                                    <FaFileDownload size={20} />
                                                </div>
                                            }
                                        </div>
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Subscription Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You need to subscribe to access this feature.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Link to="https://transactions.sendowl.com/subscriptions/30610/F24F8A1E/view" className="link_text">
                        <Button variant="secondary">
                            Subscribe to create an account

                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CardComponent;
