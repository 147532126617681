import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./Forgot.scss"

const SendOtp = ({ formData, setFormData, validateEmail }) => {
  const [emailValid, setEmailValid] = useState(true);
  return (<>
    <div className="forgot_form">
      <div className="forgot_form_container">
        <Form>
          <Form.Group className="input_field">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Email"
              value={formData.email}
              onChange={(e) => {
                const newEmail = e.target.value;
                setFormData({ ...formData, email: newEmail });
                setEmailValid(validateEmail(newEmail));
              }}
              isInvalid={!emailValid}
            />
            <Form.Control.Feedback type="invalid">
              Invalid email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="input_field">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter OTP"
              disabled
            />
          </Form.Group>
          <Form.Group className="input_field">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter New Password"
              disabled
            />
          </Form.Group>
          <Form.Group className="input_field">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Confirm New Password"
              disabled
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  </>
  );
};
export default SendOtp;
