import React, { useState } from 'react';
import './Banner.scss';
import { Container } from 'react-bootstrap';
import Skeleton from '@material-ui/lab/Skeleton';
import BannerPic from '../../assets/apt.png';

const Banner = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <div  className="Banner">
      <div className="section1">
        <div className="left">
          <h3>{props?.CalculatorName}</h3>
          <p>{props?.CalculatorDesc}</p>
        </div>
        <div className="right">
          {!imageLoaded && (
            <Skeleton variant="rectangular" width={280} height={280} />
          )}
          <img
            src={props.CalculatorImage === undefined ? BannerPic : `${props.CalculatorImage}`}
            width={100}
            height={100}
            alt="Banner_img"
            className={`picture ${imageLoaded ? 'loaded' : 'not-loaded'}`}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={handleImageLoaded}
          />
        </div>
      </div>
    </div>
  )
}

export default Banner;
