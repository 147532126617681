function calculateNetProfitMargin(revenue, cost) {
    let netProfit = revenue - cost;
    let netProfitMargin = (netProfit / revenue) * 100;
    return netProfitMargin;
};

function calculateProfitPercentage(netProfit, cost) {
    let profitPercentage = (netProfit / cost) * 100;
    return profitPercentage;
};

export function profitMarginCalculator(revenue, cost) {

    if (!cost > 0 || !revenue > 0) {
        return
    };

    let netProfitMargin = calculateNetProfitMargin(revenue, cost);
    let netProfit = revenue - cost;
    let profitPercentage = calculateProfitPercentage(netProfit, cost);

    let response = {
        "netProfitMargin": netProfitMargin.toFixed(2),
        "netProfit": netProfit.toFixed(2),
        "profitPercentage": profitPercentage.toFixed(2)
    }
    return response;

};

// let cost = 100;
// let revenue = 150;

// profitMarginCalculator(cost, revenue);