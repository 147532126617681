import React, { useEffect } from 'react'
import { Button, Checkbox, Form, Input, message } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./SendOtp.scss"
import Image from "../../assets/Automated_Pricing_Calc.jpg"
import { Container } from 'react-bootstrap';
import { SendOTPAPI, VerifyOtpAPI } from '../../Constants/Api/Api';
const SendOtpComponent = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const onFinish = (values) => {
    SendOTPAPI(values?.useremail).then((res) => {
      navigate("/verifyotp", {
        state: {
          email: values.useremail,
        }
      });
      message.success(res?.data?.message)
    }).catch((error) => {
      console.log(error, "error")
      message.error(error.response.data.message)
    })
  };
  const onFinishFailed = (errorInfo) => {
  };
  return (
    <Container className="VerifyOtp main-container">
      <div className="Main" >
        <div className="Left">
          <img src={Image} width="100%" alt="logo" />
        </div>
        <div className="Right">
          <div className="form_inner">
            <h1>Sign up</h1>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="User Email"
                name="useremail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
              >
                <Button htmlType="submit" className="button_theme">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          <p className="buttom_text">Already have an account? <Link to="">
            Sign in
          </Link>
          </p>
        </div>
      </div>
    </Container>
  )
}
export default SendOtpComponent
