import React, { useEffect, useState } from "react";
// import { Button } from "antd";
import { Form, Input } from 'antd';
import { UserLoginAPI } from "../../Constants/Api/Api";
import { Link, useNavigate } from "react-router-dom";
import DescriptionAlerts from "../../Constants/alert/alert";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Logo from "../../assets/apt.png"
import "./signin.scss"
import { Button } from "react-bootstrap";
const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const checkEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    setAlert(false)
    if (!checkEmailFormat(email)) {
      setIsValidEmail(false);
      return;
    }
    setIsValidEmail(true);
    UserLoginAPI(email, password)
      .then((res) => {
        // console.log(res, "response login")
        if (res?.status === 200 || res?.data?.status === 200) {
          setTimeout(() => {
            const token = res?.data?.data?.tokens?.access?.token;
            if (res?.data?.data?.user_status === "DEACTIVATE" && res?.data?.data?.is_trial_period === false) {
              localStorage.removeItem("UserLoginTokenApt");
              localStorage.removeItem("UserStatus");
              localStorage.setItem("UserStatus", res?.data?.data?.user_status);
              localStorage.removeItem("is_active");
              localStorage.setItem("is_active", res?.data?.data?.is_active);
              setAlert(true);
              setAlertConfig({
                text: "Your trial period is over. Please subscribe to log in again.",
                icon: "error",
              });
            }
            if (res?.data?.data?.user_status === "DEACTIVATE" && res?.data?.data?.is_subscribed === false) {
              localStorage.removeItem("UserLoginTokenApt");
              localStorage.removeItem("UserStatus");
              localStorage.setItem("UserStatus", res?.data?.data?.user_status);
              localStorage.removeItem("is_active");
              localStorage.setItem("is_active", res?.data?.data?.is_active);
              setAlert(true);
              setAlertConfig({
                text: "Subscription expired. Please subscribe to log in again.",
                icon: "error",
              });
            }
            if (res?.data?.data?.user_status === "ACTIVATE" || res?.data?.data?.user_status === "ACTIVATE TRIAL") {
              localStorage.removeItem("UserLoginTokenApt");
              localStorage.setItem("UserLoginTokenApt", token);
              localStorage.removeItem("UserStatus");
              localStorage.setItem("UserStatus", res?.data?.data?.user_status);
              localStorage.removeItem("is_active");
              localStorage.setItem("is_active", res?.data?.data?.is_active);
              setAlert(true);
              setAlertConfig({
                text: "Congratulations! You have successfully logged in.",
                icon: "success",
              });
              navigate("/");
            }
          }, 4000);
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.status === 401 ||
          error?.response?.data?.status  === 400
        ) {
          setAlert(true);
          setAlertConfig({
            text: error.response.data.message,
            icon: "error",
          });
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])
  return (
    <>
      {alert ? (
        <DescriptionAlerts text={alertConfig.text} icon={alertConfig.icon} />
      ) : null}
      <div className="Signin">
        <div className="Main">
          <div className="Left">
            <img src={Logo} width="100%" alt="logo" />
          </div>
          <div className="Right">
            <div className="form_inner">
              <h1>Sign in</h1>
              <Form layout="vertical">
                <Form.Item label="Email" className="mb-3" validateStatus={!isValidEmail ? "error" : ""} help={!isValidEmail && "Please enter a valid email address."}>
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsValidEmail(checkEmailFormat(e.target.value));
                    }}
                  />
                </Form.Item>
                <Form.Item label="Password" className="mb-3">
                  <Input.Password
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onPressEnter={handleSubmit}
                    iconRender={show => (show ? <FaEye /> : <FaEyeSlash />)}
                    onKeyPress={handleKeyPress}
                  />

                </Form.Item>
                <p
                  onClick={() => {
                    navigate("/forgotpassword")
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Forgot password?
                </p>
              </Form>
              <Button className="button_theme" onClick={handleSubmit}>
                Sign in
              </Button>
              <p className="account_text">
                <Link to="https://transactions.sendowl.com/subscriptions/30610/F24F8A1E/view" className="link_text">
                  Subscribe to create an account
                </Link>
                <br/>
                <span >
                <Link to="/send-email-verification" className="link_text">
                  Register for free trail
                </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signin;
