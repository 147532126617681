function calculateSellingPrice(cost, grossMargin) {
    let revenue = cost / (1 - grossMargin);
    return revenue;
};
function calculateGrossProfit(revenue, grossMargin) {
    let grossProfit = revenue * grossMargin;
    return grossProfit;
};
function calculateMarkupPercentage(grossProfit, cost) {
    let markupPercentage = (grossProfit / cost) * 100;
    return markupPercentage;
};
export function priceCalculator(cost, grossMargin) {
    if (!cost > 0 || grossMargin >= 100 || grossMargin <= 0) {
        return
    };
    grossMargin = grossMargin / 100;
    let revenue = calculateSellingPrice(cost, grossMargin);
    let grossProfit = calculateGrossProfit(revenue, grossMargin);
    let markupPercentage = calculateMarkupPercentage(grossProfit, cost);
    let response = {
        "R": revenue.toFixed(2),
        "P": grossProfit.toFixed(2),
        "M": markupPercentage.toFixed(2)
    }
    return response;
};
// let cost = 100;
// let grossMargin = 1;
// priceCalculator(cost, grossMargin);
