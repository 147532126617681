import { useEffect } from "react";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default function DescriptionAlerts({ text, icon }) {
  useEffect(() => {
    Swal.fire({
      text: text,
      icon: icon,
      confirmButtonText: 'Ok'
    });
  }, [text, icon]);

  return null;
}

