import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { profitMarginCalculator } from '../../../CalculatorFunction/profitMarginCalculatorFunction'
import { CalculatorViewApi } from '../../../Constants/Api/Api'
const ProfitCalculatorComponent = ({ calculatordetails }) => {
  const [cost, setCost] = useState()
  const [revenue, setRevenue] = useState()
  const [result, setResult] = useState()
  const handleSubmit = async () => {
    try {
      let data = await profitMarginCalculator(revenue, cost)
      setResult(data);
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])
  const storedValue = localStorage.getItem("UserLoginTokenApt");


  const handleView = (id) => {
    CalculatorViewApi(id,storedValue).then((res) => {
    }).catch((error) => console.log(error, "error"))
  }
  return (
    <div>
      <Form>
        <span className='add_placeholder_doller'>$</span>
        <Form.Group className='mb-2' >
          <Form.Control placeholder="Enter Cost" value={cost} onChange={(e) => { setCost(e.target.value) }} />
        </Form.Group>
        <span className='add_placeholder_doller'>$</span>
        <Form.Group className='mb-2'  >
          <Form.Control placeholder="Enter Revenue" value={revenue} onChange={(e) => { setRevenue(e.target.value) }} />
        </Form.Group>
      </Form>
      <div className='Button_style'>
        <Button className="button_theme" onClick={() => { setCost(""); setRevenue("") }}>Clear</Button>
        <Button className="button_theme" onClick={() => { handleSubmit(); handleView(calculatordetails?.calculatordetails?.id) }}>Calculate
        </Button>
      </div>
      {result &&
        <div className='Answer_Container'>
          <h5>Answer</h5>
          <p>Net Profit Margin : {result.netProfitMargin}%</p><p>
            Net Profit : ${result.netProfit}</p><p>
            Profit Percentage : {result.profitPercentage}%</p>
        </div>
      }
    </div>


  )
}
export default ProfitCalculatorComponent
