import axios from "axios";
const BASE_URL = "https://node.automatedpricingtool.io:5000/api/v1/";
// ************SEND OTP API****************
export const SendOTPAPI = async (value1) => {
  let config = {
    email: value1,
    type: "email_verification",
  };
  return await axios.post(BASE_URL + "auth/otp", config);
};
export const ForgetSendOTPAPI = async (
  value1
) => {
  let config = {
    email: value1,
    type: "forget_password"
  };
  return await axios.post(BASE_URL + "auth/otp", config);
};
// ************Verify OTP API****************
export const VerifyOtpAPI = async (value1, value2) => {
  let config = {
    email: value1,
    otp: value2,
    type: "email_verification",
  };
  return await axios.post(BASE_URL + "auth/verify-otp", config);
};
// ************User Register API****************
export const UserRegisterAPI = async (
  value
) => {
  return await axios.post(BASE_URL + "auth/register", value);
};
// ************User Login API****************
export const UserLoginAPI = async (value1, value2) => {
  let config = {
    email: value1,
    password: value2,
  };
  return await axios.post(BASE_URL + "auth/login", config);
};
// ************User Logout API****************
export const UserLogOutAPI = async (value) => {
  return await axios.get(BASE_URL + "auth/logout", {
    headers: {
      "x-access-token": value,
    },
  });
};
// ************Getprofile API****************
export const GetProfile = async (token) => {
  try {
    const response = await axios.get(BASE_URL + "user/profile", {
      headers: {
        "x-access-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("API response error:", error);
    throw error;
  }
};
// ************Profile Edit API****************
export const UserEditProfileAPI = async (value1, value2, value3, Value) => {
  let config = {
    name: value1,
    images: value2,
    mobile: value3,
  };
  return await axios.put(BASE_URL + "user/updateProfile", config, {
    headers: {
      "x-access-token": Value,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
// ************Change Password API****************
export const ChangePasswordAPI = async (value1, value2, value3, Value) => {
  let config = {
    old_password: value1,
    new_password: value2,
    confirm_password: value3,
  };
  return await axios.post(BASE_URL + "auth/reset-password", config, {
    headers: {
      "x-access-token": Value,
    },
  });
};
// ************getCalculatorFromSlugAPI****************
export const GetCalculatorDescription = async (value1) => {
  let config = {
    calculator_slug: value1,
  };
  return await axios.post(BASE_URL + "calculator/getCalculatorFromSlug", config);
};
// ************Get All Proposal API****************
export const GetAllProposal = async () => {
  const res = await axios.get(BASE_URL + "proposal/all");
  return res;
};
// ************Get Proposal BY ID API****************
export const GetProposalById = async (id) => {
  return await axios.get(BASE_URL + "proposal/getProposalById?id=" + id);
};
// ************Get Proposal category list****************
export const GetProposalCategoryList = async (id) => {
  return await axios.get(BASE_URL + "category/all");
};
// ************Proposal by Category API****************
export const ProposalByCategoryApi = async (
  value1, limit, page
) => {
  let config = {
    category_slug: value1
  };
  return await axios.post(BASE_URL + `proposal/get-proposal-from-category?limit=${limit}&page=${page}`, config
  );
};
// ************Get Proposal BY ID API****************
export const GetHomePageContent = async () => {
  return await axios.get(BASE_URL + "content/getAllContent");
};
// ************Forgot Password API****************
export const ForgotPassword = async (value1, value2, value3, value4) => {
  let config = {
    email: value1,
    otp: value2,
    password: value3,
    confirm_Password: value4
  };
  return await axios.post(BASE_URL + "auth/forgot-password", config);
};
// ************ProposalView API****************
export const ProposalViewApi = async (proposal_id, storedValue) => {
  return await axios.post(BASE_URL + `USER/markProposalAsViewed?proposal_id=${proposal_id}`, null, {
    headers: {
      "x-access-token": storedValue,
    },
  });
};
// ************CalculatorView API****************
export const CalculatorViewApi = async (calculator_id, storedValue) => {
  return await axios.post(BASE_URL + `user/markCalculatorAsViewed?calculator_id=${calculator_id}`, null, {
    headers: {
      "x-access-token": storedValue,
    },
  });
};
// ************Edit Proposal API****************
// export const EditProposalAPI = async (value1, value2, storedValue) => {
//   const pdf = value2;
//   const formData = new FormData(); // Use a single FormData instance
//   const blob = pdf.output("blob");
//   formData.append("proposal_id", value1);
//   formData.append("docs", blob, "generated.pdf");
//   return await axios.post(BASE_URL + "proposal/createProposalForUser", formData, {
//     headers: {
//       "x-access-token": storedValue,
//       "Content-Type": "multipart/form-data",
//       Accept: "application/json",
//     },
//   });
// };
export const GetBanner = async () => {
  const res = await axios.get(BASE_URL + "content/getAllBannerContent");
  return res;
};
// footer
export const FooterContentApi = async () => {
  const res = await axios.get(BASE_URL + "content/getAllSocialLogin");
  return res;
};
// Calculation Save aS Draft
export const DraftCalculationApi = async (data, storedValue) => {
  const headers = {
    'x-access-token': storedValue
  };
  return await axios.post(BASE_URL + 'proposal/createProposalForUserUsingCalculatorData', data, { headers });
};
// Calculation get aS Draft
export const GetDraftCalculationApi = async (storedValue) => {
  const headers = {
    'x-access-token': storedValue
  };
  return await axios.get(BASE_URL + 'proposal/getAllUserProposal', { headers });
};
// Update Draft
export const UpdateDraft = async (draft_id, data, storedValue) => {
  const headers = {
    'x-access-token': storedValue
  };
  return await axios.post(BASE_URL + `proposal/updateUserDraft/${draft_id}`, data, { headers });
};
// check userStatus 
export const checkUserStatus = async (storedValue) => {
  const headers = {
    'x-access-token': storedValue
  };
  return await axios.get(BASE_URL + 'user/checkUserStatus', { headers });
};
// delete Draft
export const deleteDraft = async (value, storedValue) => {
  let config = {
    draft_id: value
  }
  const headers = {
    'x-access-token': storedValue
  };
  return await axios.post(BASE_URL + 'proposal/deleteUserDraft', config, { headers });
};