import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap'
import "./AdvancedPrice.scss"
import { useLocation, useNavigate } from 'react-router-dom';
function Lms({ handleCloseBH }) {
    const firstSelectValue = 'Lms';
    const [secondSelectValue, setSecondSelectValue] = useState('');
    const [showParticipants, setShowParticipants] = useState(false);
    const [participantsValue, setParticipantsValue] = useState('');
    const [aupValue, setAduValue] = useState('');
    const [customParticipants, setCustomParticipants] = useState('');
    const [customAdu, setCustomAdu] = useState();
    const [Per_No_Discount, setPer_No_Discount] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [monthlyCtm, setMonthlyCTM] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
        text: "",
        icon: "",
    });
    const navigate = useNavigate()
    const location = useLocation()
    let slug = new URLSearchParams(location?.search).get("s");
  
    const levelsData = {
        'Lms': {
            'Level 1': { Max_Users: '1-299', aup: '$3.50 - $4.87', ctm: '195.00' },
            'Level 2': { Max_Users: '300-500', aup: '$3.48 - $4.80', ctm: '695.00' },
            'Level 3': { Max_Users: '501+', aup: '$2.49 - $3.45', ctm: '995.00' }
        },
    };
    const handleSecondSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (firstSelectValue && levelsData[firstSelectValue] && levelsData[firstSelectValue][selectedValue]) {
            setSecondSelectValue(selectedValue);
            const selectedLevel = levelsData[firstSelectValue][selectedValue];
            setParticipantsValue(selectedLevel.Max_Users);
            setAduValue(selectedLevel.aup);
            setMonthlyCTM(selectedLevel.ctm)
            setShowParticipants(true);
        } else {
            setShowParticipants(false);
            setParticipantsValue('');
            setAduValue('');
        }
    };
    const handleCustomParticipantsChange = (e) => {
        const value = e.target.value;
        setCustomParticipants(value);
        calculateResult(value, customAdu);
    };
    const handleCustomAduChange = (e) => {
        const value = e.target.value;
        setCustomAdu(value);
        calculateResult(customParticipants, value);
    };
    const handleDiscountChange = (e) => {
        const value = e.target.value;
        setDiscountValue(value);
        calculateDiscount(value);
    };
    const calculateResult = (participants, aup) => {
        if (participants && aup) {
            const result = parseInt(participants) * parseFloat(aup);
            setPer_No_Discount(result);
            calculateDiscount(discountValue);
        } else {
            setPer_No_Discount('');
            setDiscountAmount('');
        }
    };
    const calculateDiscount = (discount) => {
        if (Per_No_Discount && discount) {
            const discountAmount = (Per_No_Discount * discount).toFixed(2)
            setDiscountAmount(discountAmount);
        } else {
            setDiscountAmount('');
        }
    };
    const handleStoreData = () => {
        // const data = {
        //     heading: "LMS",
        //     Custom_Participants: customParticipants,
        //     Custom_Adu: customAdu,
        //     Monthly_Revenue: Per_No_Discount,
        //     Annual_Revenue: (Per_No_Discount * 12).toFixed(2),
        //     Discount_Value: discountValue * 100,
        //     Discount_Amount: discountAmount,
        //     After_Discount: ((Per_No_Discount - discountAmount).toFixed(2)),
        //     Annual_After_Discount: ((Per_No_Discount - discountAmount) * 12).toFixed(2),
        //     Monthly_Cost_from_CTM: monthlyCtm,
        //     Annual_Monthly_Cost_from_CTM: (monthlyCtm * 12).toFixed(2),
        //     CTM_Cost_Percentage: (((monthlyCtm * 12) / (Per_No_Discount * 12)) * 100).toFixed(2),
        //     Annual_CPI_3: ((Per_No_Discount * 12) * 0.03).toFixed(2),
        //     Annual_CPI_5: ((Per_No_Discount * 12) * 0.05).toFixed(2)
        // };

        const data = {
            heading: "LMS",
            Custom_Participants: customParticipants,
            Custom_Adu: customAdu,
            Monthly_Revenue: Per_No_Discount,
            Annual_Revenue: (Per_No_Discount * 12).toFixed(2),

        };

        if (discountValue !== "no") {
            data.Discount_Value = discountValue * 100;
            data.Discount_Amount = discountAmount;
            data.After_Discount = ((Per_No_Discount - discountAmount).toFixed(2));
            data.Annual_After_Discount = ((Per_No_Discount - discountAmount) * 12).toFixed(2);
        };

        data.Monthly_Cost_from_CTM = monthlyCtm;
        data.Annual_Monthly_Cost_from_CTM = (monthlyCtm * 12).toFixed(2);
        data.CTM_Cost_Percentage = (((monthlyCtm * 12) / (Per_No_Discount * 12)) * 100).toFixed(2);
        data.Annual_CPI_3 = ((Per_No_Discount * 12) * 0.03).toFixed(2);
        data.Annual_CPI_5 = ((Per_No_Discount * 12) * 0.05).toFixed(2)

        localStorage.removeItem("Calculation");
        localStorage.setItem('Calculation', JSON.stringify(data));
        setAlertConfig({
            text: "Data Stored For Proposal.",
            icon: "success",
        });
        setAlert(true);
        setTimeout(() => {
            if (!slug) {
                navigate("/create/leadership-workshop-proposal");
            }
        }, 2000);
    }
    return (
        <div className="AdvancedPrice">
            <div className='Calculator_container'>
                <div className='Row_1'>
                    <div className='Col_1' style={{ padding: "4px" }} >
                        <div className='blank_input' style={{ width: "inherit", padding: "7px" }}>LMS</div>
                    </div>
                    <div className='Col_1'>
                        <Form.Select value={secondSelectValue} onChange={handleSecondSelectChange} style={{ padding: "9px" }} >
                            <option value="">select--</option>
                            {levelsData[firstSelectValue]
                                ? Object.keys(levelsData[firstSelectValue]).map((level, index) => (
                                    <>
                                        <option key={index} value={level}>
                                            {level}
                                        </option>
                                    </>
                                ))
                                : null}
                        </Form.Select>
                    </div>
                </div>
                {showParticipants && (<>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Max Users:
                            <div className='Col_12'>
                                <div className='blank_input'>{participantsValue}</div>
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Participants'
                                    value={customParticipants}
                                    onChange={handleCustomParticipantsChange} /></div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>AUP:
                            <div className='Col_12'>
                                <div className='blank_input'>{aupValue}</div>
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Aup
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Aup'
                                    value={customAdu}
                                    onChange={handleCustomAduChange} /></div>
                        </div>
                    </div>
                </>
                )
                }
                {Per_No_Discount && (<>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Monthly Revenue :
                            <div className='Col_12 blank_input'>${(Per_No_Discount).toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual Revenue :
                            <div className='Col_12 blank_input'>${(Per_No_Discount * 12).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </>
                )}
                {showParticipants &&
                    <Form.Select value={discountValue} onChange={handleDiscountChange} style={{ padding: "7px" }}>
                        <option value="">Select discount</option>
                        <option value="no">No discount</option>
                        <option value={0.02}>2%</option>
                        <option value={0.05}>5%</option>
                        <option value={0.1}>10%</option>
                        <option value={0.12}>12%</option>
                        <option value={0.15}>15%</option>
                        <option value={0.20}>20%</option>
                        <option value={0.25}>25%</option>
                        <option value={0.30}>30%</option>
                    </Form.Select>}
                {discountAmount && discountValue !== "no" && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Discount Amount :
                            <div className='Col_12 blank_input'>${discountAmount}
                            </div>
                        </div>
                    </div>
                )}
                {discountAmount && discountValue !== "no" && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Per (Month or Session) After Discount :
                            <div className='Col_12 blank_input'>${(Per_No_Discount - discountAmount).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {discountAmount && discountValue !== "no" && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual After Discount :
                            <div className='Col_12 blank_input'>${((Per_No_Discount - discountAmount) * 12).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Your Monthly Cost from CTM:
                            <div className='Col_12 blank_input'>${monthlyCtm}
                            </div>
                        </div>
                    </div>
                )}
                {discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Your Annual Cost from CTM :
                            <div className='Col_12 blank_input'>${(monthlyCtm * 12).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            CTM Cost Percentage:
                            <div className='Col_12 blank_input'>{(((monthlyCtm * 12) / (Per_No_Discount * 12)) * 100).toFixed(2)}%
                            </div>
                        </div>
                    </div>
                )}
                {discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual CPI 3% :
                            <div className='Col_12 blank_input'>${((Per_No_Discount * 12) * 0.03).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual CPI 5%:
                            <div className='Col_12 blank_input'>${((Per_No_Discount * 12) * 0.05).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Button className="button_theme"
                onClick={() => { handleStoreData(); handleCloseBH() }}>Add to proposal
            </Button>
        </div>
    );
}
export default Lms;
