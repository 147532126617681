import React, { useState } from 'react';

function Try() {
  const [person, setPerson] = useState('');

  const myFunction = () => {
    const userInput = prompt("Please enter your name", "Harry Potter");
    if (userInput !== null) {
      setPerson(userInput);
    }
  };

  return (
    <div>
      <h1>The Window Object</h1>
      <h2>The prompt() Method</h2>
      <p>Click the button to demonstrate the prompt box.</p>
      <button onClick={myFunction}>Try it</button>
      {person && <p>Hello {person}! How are you today?</p>}
    </div>
  );
}

export default Try;
