import React, { useEffect, useState } from "react";
import "./SellingPriceCalculator.scss";
import { Button, Form, Table } from "react-bootstrap";
import { sellingPriceCalculator } from "../../../CalculatorFunction/sellingPriceCalculator";
import { CalculatorViewApi } from "../../../Constants/Api/Api";
const SellingPriceCalculatorComponent = (calculatordetails) => {
  const [cost, setCost] = useState("");
  const [itemCost, setItemCost] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [sellingCostPercentage, setSellingCostPercentage] = useState(0);
  const [sellingCostFixedPrice, setSellingCostFixedPrice] = useState(0);
  const [tnxCostPercentage, setTnxCostPercentage] = useState(0);
  const [tnxCostFixedPrice, setTnxCostFixedPrice] = useState(0);
  const [selectedValue, setSelectedValue] = useState("1");
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");

  const handleFirstSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (Number(itemCost) <= 0) {
        setError("Item Cost must be greater than 0");
        return;
      }

      if (
        Number(sellingCostPercentage) >= 100 ||
        Number(tnxCostPercentage) >= 100
      ) {
        setError("Percentage should be less than 100 or equal to 100");
        return;
      }

      if (selectedValue === "1") {
        let data = await sellingPriceCalculator(
          Number(cost),
          0,
          0,
          Number(itemCost),
          Number(shippingCost),
          Number(shippingCharge),
          Number(sellingCostPercentage),
          Number(sellingCostFixedPrice),
          Number(tnxCostPercentage),
          Number(tnxCostFixedPrice)
        );
        setResult(data);
      } else if (selectedValue === "2") {
        if (Number(cost) >= 100) {
          setError("Margin should be less than 100 or equal to 100");
          return;
        }
        let data = await sellingPriceCalculator(
          0,
          Number(cost),
          0,
          Number(itemCost),
          Number(shippingCost),
          Number(shippingCharge),
          Number(sellingCostPercentage),
          Number(sellingCostFixedPrice),
          Number(tnxCostPercentage),
          Number(tnxCostFixedPrice)
        );
        setResult(data);
      } else if (selectedValue === "3") {
        let data = await sellingPriceCalculator(
          0,
          0,
          Number(cost),
          Number(itemCost),
          Number(shippingCost),
          Number(shippingCharge),
          Number(sellingCostPercentage),
          Number(sellingCostFixedPrice),
          Number(tnxCostPercentage),
          Number(tnxCostFixedPrice)
        );
        setResult(data);
      }
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  const storedValue = localStorage.getItem("UserLoginTokenApt");

  const handleView = (id) => {
    CalculatorViewApi(id,storedValue).then((res) => {
    }).catch((error) => console.log(error, "error"))
  }
  return (
    <div className="SellingPriceCalculatorComponent">

      <Form>
        <div className="cal_row">
          <div className="cal_col" style={{ marginBottom: "32px" }}>
            <Form.Select onChange={handleFirstSelectChange}>

              <option value="1">Profit $</option>
              <option value="2">Margin %</option>
              <option value="3">Markup %</option>
            </Form.Select>
          </div>
          <div className="cal_col" >
            <Form.Group  >
              <span className="add_placeholder_doller">$</span>
              <Form.Control
                placeholder="Enter Cost"
                onChange={(e) => {
                  setCost(e.target.value);
                }}
              />
            </Form.Group>
            <p className="subvalues">(desired return)	</p>

          </div>

        </div>

        <span className="add_placeholder_doller">$</span>
        <Form.Group className='mb-2' >
          <Form.Control
            placeholder="Enter Item Cost"
            onChange={(e) => {
              setItemCost(e.target.value);
            }}
          />
        </Form.Group>
        <span className="add_placeholder_doller">$</span>
        <Form.Group className='mb-2' >
          <Form.Control
            placeholder="Enter Shipping Cost"
            onChange={(e) => {
              setShippingCost(e.target.value);
            }}

          />
          <p className="subvalues">(amount paid by seller)	</p>
        </Form.Group>
        <span className="add_placeholder_doller">$</span>
        <Form.Group  >
          <Form.Control
            placeholder="Enter Shipping Charge"
            onChange={(e) => {
              setShippingCharge(e.target.value);
            }}
          />
        </Form.Group>
        <p className="subvalues">(amount charged to buyer)
        </p>
      </Form>
      <h5>Selling Cost<span className="subvalues">&nbsp;&nbsp;(e.g. Amazon, eBay or Etsy Fee)</span></h5>
      <Form>
        <span className="add_placeholder_doller">%</span>
        <Form.Group className='mb-2' >
          <Form.Control
            placeholder="Enter Percentage"
            onChange={(e) => {
              setSellingCostPercentage(e.target.value);
            }}
          />
        </Form.Group>
        <span className="add_placeholder_doller">$</span>
        <Form.Group className='mb-2' >
          <Form.Control
            placeholder="Enter Fixed"
            onChange={(e) => {
              setSellingCostFixedPrice(e.target.value);
            }}
          />
        </Form.Group>
      </Form>
      <h5>Transaction Cost<span className="subvalues">&nbsp;&nbsp;(e.g. CC or PayPal Fee)</span></h5>
      <Form>
        <span className="add_placeholder_doller">%</span>
        <Form.Group className='mb-2' >
          <Form.Control
            placeholder="Enter Percentage"
            onChange={(e) => {
              setTnxCostPercentage(e.target.value);
            }}
          />
        </Form.Group>
        <span className="add_placeholder_doller">$</span>
        <Form.Group className='mb-2' >
          <Form.Control
            placeholder="Enter Fixed"
            onChange={(e) => {
              setTnxCostFixedPrice(e.target.value);
            }}
          />
        </Form.Group>
      </Form>
      <div className='Button_style'>
        <Button
          className="button_theme"

        >
          Clear
        </Button>
        <Button
          className="button_theme"

          onClick={() => { handleSubmit(); handleView(calculatordetails?.calculatordetails?.id) }}
        >
          Calculate
        </Button>
      </div>
      {error && <p>{error}</p>}
      {result && (
        <div className="Answer_Container">
          <h5>Answer</h5>
          <p>Selling Price = $ {result ? result.answer.sellingPrice : 0}</p>
          <h6>Summary of Values</h6>
          <Table bordered style={{ background: "white" }}>
            <thead style={{ background: "#F5F5F5" }}>
              <tr>
                <th>Discount</th>
                <th>Final Price Each</th>
              </tr>
            </thead>
            <tbody style={{ color: "#A0A0A0" }}>
              <tr>
                <td>Item Cost</td>
                <td>$ {result.summaryOfValues.itemCost}</td>
              </tr>
              <tr>
                <td>Shipping Cost</td>
                <td>$ {result.summaryOfValues.shippingCost}</td>
              </tr>
              <tr>
                <td>Selling Cost</td>
                <td>$ {result.summaryOfValues.sellingCost}</td>
              </tr>
              <tr>
                <td>Transaction Cost</td>
                <td>$ {result.summaryOfValues.transactionCost}</td>
              </tr>
              <tr>
                <td>Cost: (total)</td>
                <td>$ {result.summaryOfValues.cost}</td>
              </tr>
              <tr>
                <td>Revenue</td>
                <td>$ {result.summaryOfValues.revenue}</td>
              </tr>
              <tr>
                <td>Profit</td>
                <td>$ {result.summaryOfValues.profit}</td>
              </tr>
              <tr>
                <td>Margin</td>
                <td>{result.summaryOfValues.margin} %</td>
              </tr>
              <tr>
                <td>Markup</td>
                <td>{result.summaryOfValues.markup} %</td>
              </tr>
            </tbody>
          </Table>
          <h6>Example Invoice for Buyer:</h6>
          <Table bordered style={{ background: "white" }}>
            {/* <thead style={{ background: "#F5F5F5" }}>
                <tr>
                  <th>Item Price</th>
                  <th>Final Price Each</th>
                </tr>
              </thead> */}
            <tbody style={{ color: "#A0A0A0" }}>
              <tr>
                <td>Item Price</td>
                <td>$ {result.invoiceForBuyer.itemPrice}</td>
              </tr>
              <tr>
                <td>Shipping</td>
                <td>$ {result.invoiceForBuyer.shipping}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>$ {result.invoiceForBuyer.total}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>

  );
};
export default SellingPriceCalculatorComponent;
