import React, { useEffect, useState } from 'react'
import "./Footer.scss"
import { Container } from 'react-bootstrap'
import { FaInstagramSquare, FaTwitter } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { PiSpotifyLogoFill } from "react-icons/pi";


import { FooterContentApi } from '../../Constants/Api/Api'
import { Link } from 'react-router-dom'
const FooterSection = () => {
    const [social, setSocial] = useState()
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        FooterContentApi().then((res) => {
            setSocial(res.data.data)
        }).catch((error) => {
            console.log(error, "error in footer")
        })
    }, [])

    return (
        <div className="FooterSection">
            <Container className="footer_inner_desktop">
                <div className="sec1">
                    <p>© {currentYear} Automated Price Tool . All rights reserved.</p>
                </div>
                <div className="sec1">
                    Automated Pricing Tool
                </div>
                <div className="sec1">
                    {social?.map((item, index) => {
                        return (
                            <>
                                {item.social_media_name === "Twitter" ?
                                    <Link to={`${item?.redirection_url}`} key={index}>
                                        <FaTwitter className="icon" size={25} />
                                    </Link> : item.social_media_name === "Facebook" ?
                                        <Link to={`${item?.redirection_url}`} key={index}>
                                            <FaFacebook className="icon" size={25} />
                                        </Link> : item.social_media_name === "Instagram" ?
                                            <Link to={`${item?.redirection_url}`} key={index}>
                                                <FaInstagramSquare className="icon" size={25} />
                                            </Link> : item.social_media_name === "Youtube" ?
                                                <Link to={`${item?.redirection_url}`} key={index}>
                                                    <IoLogoYoutube className="icon" size={25} />
                                                </Link> : item.social_media_name === "FaLinkedin" ?
                                                    <Link to={`${item?.redirection_url}`} key={index}>
                                                        <FaLinkedin className="icon" size={25} />
                                                    </Link> : item.social_media_name === "Spotify" ?
                                                        <Link to={`${item?.redirection_url}`} key={index}>
                                                            <PiSpotifyLogoFill className="icon" size={25} />
                                                        </Link> : ""

                                }
                            </>
                        )
                    })}
                </div>
            </Container>

        </div>
    )
}
export default FooterSection
