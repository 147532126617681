import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { marginCalculator } from "../../../CalculatorFunction/marginCalculatorFunction"
import { CalculatorViewApi } from '../../../Constants/Api/Api'
const MarginCalculatorComponent = (calculatordetails) => {
  const [cost, setCost] = useState()
  const [revenue, setRevenue] = useState()
  const [result, setResult] = useState()

  const handleSubmit = async () => {
    if (cost <= 0 || revenue <= 0) {
      // If cost or revenue is not a positive number, show a message
      setResult("nagative"); // Clear previous result
      return;
    }
    try {
      let data = await marginCalculator(cost, revenue);
      setResult(data);
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])
  const storedValue = localStorage.getItem("UserLoginTokenApt");

  const handleView = (id) => {
    CalculatorViewApi(id,storedValue).then((res) => {
    }).catch((error) => console.log(error, "error"))
  }
  return (
    <div className="MarginCalculatorComponent">
      
        <Form>
          <span className='add_placeholder_doller'>$</span>
          <Form.Group className='mb-2'>
            <Form.Control title="positive real number" placeholder="Enter Cost" value={cost} onChange={(e) => { setCost(e.target.value) }} />
          </Form.Group>
          <span className='add_placeholder_doller'>$</span>
          <Form.Group className='mb-2' >
            <Form.Control title="positive real number" placeholder="Enter Revenue" value={revenue} onChange={(e) => { setRevenue(e.target.value) }} />
          </Form.Group>
        </Form>
        <div className='Button_style'>
          <Button className="button_theme" onClick={() => { setCost(""); setRevenue("") }}>Clear</Button>
          <Button className="button_theme" onClick={() => { handleSubmit(); handleView(calculatordetails?.calculatordetails?.id) }}>Calculate
          </Button>
        </div>
        {
          result &&
          <div className='Answer_Container'>
            <h5>Answer</h5>
            {result === "nagative" ?
              <p className='red'>

                Enter Cost & Revenue {`>`} 0
              </p>
              :
              <>

                <p>Gross Margin  : {result.G}%</p>
                <p>
                  Markup :{result.M}%</p>
                <p>
                  Gross Profit :${result.P}</p>
              </>
            }
          </div>
        }
      </div>


  )
}
export default MarginCalculatorComponent
