// AdvancedPrice.js
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import OneTimeWorkshopComponent from './One_Time';
import AssessmentPrograms from './AssessmentPrograms';
import Lms from './Lms';
import SoftSkillPricing from './Soft_Skill_Pricing';
import { CalculatorViewApi } from '../../../Constants/Api/Api';
function AdvancedPrice({calculatordetails,handleCloseBH}) {
const storedValue = localStorage.getItem("UserLoginTokenApt");

    // console.log(calculatordetails)
    const [firstSelectValue, setFirstSelectValue] = useState('Soft Skill');
    const handleFirstSelectChange = (e) => {
        setFirstSelectValue(e.target.value);
    };
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    const handleView = () => {
        CalculatorViewApi(calculatordetails?.calculatordetails?.id,storedValue).then((res) => {
        }).catch((error) => console.log(error, "error"))
    }
    const UserStatus = localStorage.getItem("UserStatus")

    return (
        <div >
            <Form.Select value={firstSelectValue} onChange={handleFirstSelectChange} onClick={handleView}>
                <option value="Soft Skill">Soft Skill Pricing Model</option>
                <option value="One_Time">One Time All Day Workshop</option>
                <option value="AssessmentPrograms">Assessment Programs</option>
                <option value="Lms">LMS</option>
            </Form.Select>
            <br />
            {firstSelectValue === 'Soft Skill' ? (

                <SoftSkillPricing handleCloseBH={handleCloseBH}  />
            ) : firstSelectValue === 'One_Time' ? (
                UserStatus === "ACTIVATE TRIAL" ? "To access One Time All Day Workshop, please subscribe to our service." :

                    <OneTimeWorkshopComponent handleCloseBH={handleCloseBH}/>
            ) :
                firstSelectValue === 'AssessmentPrograms' ? (
                    UserStatus === "ACTIVATE TRIAL" ? "To access Assessment Programs, please subscribe to our service." :

                        <AssessmentPrograms handleCloseBH={handleCloseBH} />
                ) : firstSelectValue === 'Lms' ? (
                    UserStatus === "ACTIVATE TRIAL" ? "To access LMS, please subscribe to our service." :

                        <Lms  handleCloseBH={handleCloseBH} />
                )
                    : null
            }
        </div>
    );
}
export default AdvancedPrice;
