import React from "react";
// import { useState } from "react";
import { Form } from "react-bootstrap";
import "./OtpStep.scss";
import OTPInput from "react-otp-input";
const VerifyOtp = ({ formData, setFormData }) => {
  return (
    <div className="Verify_Otp">
      <div className="Verify_Otp_container">
        <Form style={{ textAlign: "left" }}>
          <Form.Group className="input_field mb-3">
            <Form.Label >Email</Form.Label>
            <Form.Control
              type="text"
              placeholder={formData.email}
              disabled
            />
          </Form.Group>
          <Form.Group className="input_field mb-3">
            <Form.Label  >OTP</Form.Label>
          </Form.Group>
          <OTPInput
            // value={otp}
            value={formData.otp}
            // onChange={setOtp}
            //  onChange={() => {
            //     setFormData({ ...formData, otp});
            //   }}
            onChange={(otpValue) => {
              setFormData({ ...formData, otp: otpValue });
            }}
            numInputs={4}
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={(props) => (
              <input
                {...props}
                className="Input_custom"
              // style={{
              //   width: "110px", // Adjust the width according to your preference
              //   height: "40px", // Adjust the height according to your preference
              //   fontSize: "18px",
              //   margin:"auto",
              //   textAlign: "center",
              //   color: "#FFDE05",
              //   background: "#10313B",
              //   border: "2px solid #FFDE05",
              // }}
              />
            )}
          />
        </Form>
      </div>
    </div>
  );
};
export default VerifyOtp;
