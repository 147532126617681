import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "./OtpStep.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import DescriptionAlerts from "../../Constants/alert/alert";
import { UserRegisterAPI } from "../../Constants/Api/Api";
const CreateAccount = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [bussiness_name, setBussinesssName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handlePhoneChange = (newPhone) => {
    setMobile(newPhone);
  };
  const [alert, setAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const handleCreateAccount = (event) => {
    setAlert(false);
    if (!passwordMatch) {
      // Show an alert or handle the error when passwords don't match
      setAlert(true);
      setAlertConfig({
        text: "Passwords do not match.",
        icon: "info",
      });
      return;
    }
    // if (!agree) {
    //   setAlert(true);
    //   setAlertConfig({
    //     text: "Please agree to the terms and conditions to submit the form.",
    //     icon: "info",
    //   });
    //   setTimeout(() => {
    //     setAlert(false);
    //   }, 7000);
    //   return;
    // }
    // const getToken = localStorage.getItem("UserOtpToken");
    // event.preventDefault();
    // const formDataImage = new FormData();
    // formDataImage.append("image", selectedImage);
    // if (setIsLoading === true) {
    //   setAlertConfig({
    //     text: "Your request is Submitted Please Wait for response",
    //     icon: "success",
    //   });
    // }
    UserRegisterAPI(
      name,
      formData.email,
      bussiness_name,
      mobile,
      password,
      confirmPassword
    )
      .then((res) => {
        if (res.status === 200 || res.data.status === 200) {
          setAlert(true);
          setAlertConfig({
            text: "User Created Successfully. Please Continue Your Journey",
            icon: "success",
          });
          setTimeout(() => {
            navigate("/signin");
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        if (error.response.status === 400) {
          setAlert(true);
          setAlertConfig({
            text: error.response.data.message,
            icon: "info",
          });
          setTimeout(() => {
            setAlert(false);
            // navigate("/send/otp");
          }, 4000);
        }
      });
  };

  useEffect(() => {
    setPasswordMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  return (
    <>
      {alert ? (
        <DescriptionAlerts text={alertConfig.text} icon={alertConfig.icon} />
      ) : null}
      <div className="CreateAccount">
        <div className="CreateAccount_container">
          <Form style={{ textAlign: "left" }}>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={formData.email}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Bussiness Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Bussiness Name"
                value={bussiness_name}
                onChange={(e) => setBussinesssName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile</Form.Label>
              <PhoneInput
                country={"us"}
                value={mobile}
                onChange={handlePhoneChange}
                disableDropdown={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password </Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Passsword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Passsword</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter confirm paassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {!passwordMatch && (
                <div className="text-danger">Passwords do not match.</div>
              )}
            </Form.Group>
          </Form>
          <Button className="button_theme" onClick={handleCreateAccount}>
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};
export default CreateAccount;
