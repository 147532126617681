import React from 'react'
import Image from "../assets/SalN.gif"
const Create = () => {
  return (
    <div style={{width:"20%",margin:"auto"}}>
      <img src={Image} alt='img' />
    </div>
  )
}

export default Create
