import './App.css';
import NavSection from './Components/NavSection/NavSection';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import FooterSection from './Components/Footer/Footer';
import Signin from './Pages/Signin/Signin';
import Home from './Components/Home/Home';
import Calculator from './Components/Calculator/Calculator';
import PriceCalculatorComponent from './Components/Calculator/PriceCalculator/PriceCalculator';
import ProfitCalculatorComponent from './Components/Calculator/ProfitCalculator/ProfitCalculator';
import SalePriceCalculatorComponent from './Components/Calculator/SalePriceCalculator/SalePriceCalculator';
import MarginCalculatorComponent from './Components/Calculator/MarginCalculator/MarginCalculator';
import SellingPriceCalculatorComponent from './Components/Calculator/SellingPriceCalculator/SellingPriceCalculator';
import OtpStep from './Pages/OtpStep/OtpStep';
import AdvancedPrice from './Components/Calculator/AdvancedPrice/AdvancedPrice';
import PartnershipDiscounts from './Components/Calculator/PartnershipDiscounts/PartnershipDiscounts';
import ProfitabilityModule from './Components/Calculator/ProfitabilityModule/ProfitabilityModule';
import ForgotPasswordComponent from './Components/Forgot/forgotPassword';
import ProfileComponent from './Components/Profile/ProfileComponent';
import DocIframe from './Components/Proposal/Download';
import APTSupplementProgram from './Components/APTSupplementProgram/APTSupplementProgram';
import ProposalNew from './Components/ProposalNew/ProposalNew';
import SpeakingAgreement from './Components/ProposalNew/SpeakingAgreement';
import LeadershipProposal from './Components/ProposalNew/LeadershipProposal';
import { Container } from 'react-bootstrap';
import Create from './Components/Create';
import CoachingAgreement from './Components/ProposalNew/CoachingAgreement';
import SpeakerAgreement from './Components/ProposalNew/SpeakerAgreement';
import GrossPayCalculator from './Components/Calculator/GrossPayCalculator/GrossPayCalculator';
import Draft from './Components/Draft/Draft';
import { useEffect, useState } from 'react';
import { checkUserStatus } from './Constants/Api/Api';
import Try from './Components/ProposalNew/try';
import SignUp from './Pages/Signup/Signup';
import VerifyOTP from './Pages/VerifyOTP/VerifyOTP';
import SendOtp from './Pages/OtpStep/SendOtp';
import SendOtpComponent from './Pages/SendOtp/SendOtp';
function App() {
  const storedValue = localStorage.getItem("UserLoginTokenApt");
  const UserStatus = localStorage.getItem("UserStatus")
  const is_active = localStorage.getItem("is_active")
  // useEffect(() => {
  //   const updateUserStatus = () => {
  //     checkUserStatus(storedValue).then((res) => {
  //       console.log(res)
  //       localStorage.setItem("UserStatus", res?.data?.data?.user_status);
  //       localStorage.removeItem("is_active");
  //       localStorage.setItem("is_active", res?.data?.data?.is_active);
  //       if (res?.data?.data?.user_status === "DEACTIVATE") {
  //         localStorage.removeItem("UserLoginTokenApt")
  //       }
  //     }).catch((error) => {
  //       console.log(error, "error")
  //       if (error?.response?.data?.message === "User Not Found") {
  //         localStorage.removeItem("is_active");
  //         localStorage.setItem("is_active", "user");
  //         localStorage.removeItem("UserLoginTokenApt")
  //       }
  //       if (error?.response?.message === "Please authenticate") {
  //         localStorage.removeItem("is_active");
  //         localStorage.setItem("is_active", "user");
  //         localStorage.removeItem("UserLoginTokenApt")
  //       }
  //     })
  //   }
  //   const interval = setInterval(updateUserStatus, 1000 * 5);
  //   return () => clearInterval(interval);
  //   // };
  // }, [storedValue]);
  // useEffect(() => {
  //   if (UserStatus === "DEACTIVATE") {
  //     return <Navigate to="/" />;
  //   }
  //   if (is_active === "user") {
  //     return <Navigate to="/" />;
  //   }
  // }, [UserStatus]);
  const [check, setCheck] = useState(false)
  // console.log(storedValue, "storedValue")
  useEffect(() => {
    const updateUserStatus = () => {
      checkUserStatus(storedValue)
        .then((res) => {
          // console.log("object")
          // console.log(res);
          localStorage.setItem("UserStatus", res?.data?.data?.user_status);
          localStorage.removeItem("is_active");
          localStorage.setItem("is_active", res?.data?.data?.is_active);
          if (res?.data?.data?.user_status === "DEACTIVATE") {
            localStorage.removeItem("UserLoginTokenApt");
          }
        })
        .catch((error) => {
          // console.log(error, "error");
          // console.log("object2")
          if (error?.response?.data?.message === "User Not Found" || error?.response?.message === "Please authenticate") {
            localStorage.removeItem("is_active");
            setCheck(true)
            localStorage.setItem("is_active", "user");
            localStorage.removeItem("UserLoginTokenApt");
          }
        });
    };
    if (storedValue) {
      const interval = setInterval(updateUserStatus, 1000 * 60 * 10);
      return () => clearInterval(interval);
    }
  }, [storedValue]);
  useEffect(() => {
    if (UserStatus === "DEACTIVATE" || is_active === "user") {
      // Assuming you're using this within a component that can trigger navigation
      // If not, you need to handle redirection differently
      if (check === true) {
        window.location.href = "/";
      }
    }
  }, [UserStatus, is_active]);
  return (
    <BrowserRouter>
      <NavSection />
      <Container className="content_main">
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/sign-up" element={<SignUp />} />
          <Route exact path="/send-email-verification" element={<SendOtpComponent />} />
          <Route exact path="/verifyotp" element={<VerifyOTP />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route exact path="/otp" element={<OtpStep />} />
          <Route exact path="/profile-edit" element={<ProfileComponent />} />
          <Route
            exact
            path="calculator/price-calculator"
            element={
              <Calculator>
                <PriceCalculatorComponent />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/profit-margin-calculator"
            element={
              <Calculator>
                <ProfitCalculatorComponent />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/saleprice"
            element={
              <Calculator>
                <SalePriceCalculatorComponent />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/margin-calculator"
            element={
              <Calculator>
                <MarginCalculatorComponent />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/selling-price-calculator"
            element={
              <Calculator>
                <SellingPriceCalculatorComponent />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/sale-price-calculator"
            element={
              <Calculator>
                <SalePriceCalculatorComponent />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/advanced-price-exhibit"
            element={
              <Calculator>
                <AdvancedPrice />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/partnership-pricing-volume-discounts"
            element={
              <Calculator>
                <PartnershipDiscounts />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/profitability-module"
            element={
              <Calculator>
                <ProfitabilityModule />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/hot-deals-calculator"
            element={
              <Calculator>
                <APTSupplementProgram />
              </Calculator>
            }
          />
          <Route
            exact
            path="calculator/gross-pay-calculator"
            element={
              <Calculator>
                <GrossPayCalculator />
              </Calculator>
            }
          />
          <Route exact path="/try" element={<Try />} />
          <Route exact path="/proposals" element={<ProposalNew />} />
          <Route exact path="/draft" element={<Draft />} />
          <Route exact path="/create/coaching-agreement/:id" element={<CoachingAgreement />} />
          <Route exact path="/create/speaking-agreement/:id" element={<SpeakingAgreement />} />
          <Route exact path="/create/speaker-agreement/:id" element={<SpeakerAgreement />} />
          <Route exact path="/create/leadership-workshop-proposal/:id?/:name?" element={<LeadershipProposal />} />
          <Route exact path="/create/test" element={<Create />} />
          <Route exact path="/viewproposal" element={<DocIframe />} />
          <Route exact path="/forgotPassword" element={<ForgotPasswordComponent />} />
        </Routes>
      </Container>
      <div className="footer">
        <FooterSection />
      </div>
    </BrowserRouter>
    // <Text/>
  );
}
export default App;
