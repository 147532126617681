import React, { useEffect, useState } from 'react';
import { GetDraftCalculationApi, UpdateDraft, deleteDraft } from '../../Constants/Api/Api';
import { Card, ListGroup, Modal, Row } from 'react-bootstrap';
import NoData from "../../assets/nodata.png"
import "./Draft.scss";
import { useNavigate } from 'react-router-dom';
import Signin from '../../Pages/Signin/Signin';
import { Skeleton } from 'antd';
import 'primeicons/primeicons.css';

import "primereact/resources/themes/lara-light-cyan/theme.css";

import { Button } from 'primereact/button';
import { MDBBadge, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
const Draft = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [datalength, setDataLength] = useState(false);
    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
        text: "",
        icon: "",
    });
    const navigate = useNavigate()
    const token = localStorage.getItem("UserLoginTokenApt")

    const handleUpdateDraft = (id, data) => {
        setAlertConfig({
            text: "Data Stored For Proposal.",
            icon: "success",
        });
        setAlert(true);

        setTimeout(() => {
            navigate(`/create/leadership-workshop-proposal/${data?.id}?s=${data?.draft_name}`, { state: data });
            setShow(false);
            localStorage.removeItem('Calculation')
        }, 2000);
    }

    const handleRemove = (id) => {
        deleteDraft(id, token).then((res) => {
            setAlertConfig({
                text: "Data Deleted.",
                icon: "success",
            });
            setAlert(true);

            setTimeout(() => {
                window.location.reload();
                navigate("/draft");
                setShow(false);
                localStorage.removeItem('Calculation')
            }, 2000);
        }).catch((error) => {
            console.log(error, "error")
        })
    }

    useEffect(() => {
        if (token) {
            GetDraftCalculationApi(token).then((res) => {
                // console.log(res, "res")
                setData(res?.data?.data || []);
                setLoading(false);
                if (res?.data?.data.length === 0) {
                    setDataLength(true);
                }
            }).catch((error) => {
                console.log(error, "error")
                setLoading(false);
                if (error?.response?.data?.message === "Data not found") {
                    setDataLength(true);
                }
            });
        }
    }, [token]);

    return (
        <>
            {token ?
                <div className='Draft'>
                    <h1>Draft</h1>
                    {loading &&
                        <Skeleton variant="rectangular" width={280} height={280} />
                    }
                    {datalength &&
                        <div>
                            <img src={NoData} alt="" width="100%" />
                            {/* <p>No data found</p> */}
                        </div>
                    }
                    {data.length > 0 &&
                        <MDBTable align='middle'>
                            <MDBTableHead>
                                <tr>
                                    <th scope='col'> <h4>Proposal Name</h4></th>
                                    <th scope='col'><h4>Actions</h4></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <p className='fw-bold mb-1'> {item?.draft_name === null ? "sample draft" : item?.draft_name}</p>
                                        </td>
                                        <td>
                                            <Button icon="pi  pi-file-edit" style={{ height: "30px", width: "30px" }} onClick={() => handleUpdateDraft(item?.id, item)} title='Edit' />&nbsp;&nbsp;
                                            <Button icon="pi pi-times" severity="danger" aria-label="Cancel" style={{ height: "30px", width: "30px" }} onClick={() => handleRemove(item?.id)} title='Delete' />
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    }
                </div> : <Signin />}
        </>
    );
};

export default Draft;
// import React from 'react';
// import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
// export default function App() {
//     return (
//         <MDBTable align='middle'>
//             <MDBTableHead>
//                 <tr>
//                     <th scope='col'>Name</th>
//                     <th scope='col'>Actions</th>
//                 </tr>
//             </MDBTableHead>
//             <MDBTableBody>
//                 <tr>
//                     <td>
//                         <div className='d-flex align-items-center'>
//                             <img
//                                 src='https://mdbootstrap.com/img/new/avatars/8.jpg'
//                                 alt=''
//                                 style={{ width: '45px', height: '45px' }}
//                                 className='rounded-circle'
//                             />
//                             <div className='ms-3'>
//                                 <p className='fw-bold mb-1'>John Doe</p>
//                                 <p className='text-muted mb-0'>john.doe@gmail.com</p>
//                             </div>
//                         </div>
//                     </td>
//                     <td>
//                         <MDBBtn color='link' rounded size='sm'>
//                             Edit
//                         </MDBBtn>
//                     </td>
//                 </tr>
//             </MDBTableBody>
//         </MDBTable>
//     );
// }
