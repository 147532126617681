

export function sellingPriceCalculator(targetProfitAmount = 0, targetMarginPercentage = 0, targetMarkupPercentage = 0,
    itemCostAmount = 0, shippingCostAmount = 0,
    shippingChargeAmount = 0, sellingFeePercentage,
    sellingFeeFixedAmount = 0, transactionFeePercentage = 0,
    transactionFeeFixedAmount = 0) {
    let totalCost;
    let percentageCost;
    let markup = 0, margin = 0, profit = 0, sellingCost = 0, transactionCost = 0, revenue = 0, shippingCost = 0, cost = 0;

    if (targetProfitAmount !== null && targetProfitAmount !== 0) {
        totalCost = (itemCostAmount + targetProfitAmount + shippingCostAmount + sellingFeeFixedAmount + transactionFeeFixedAmount);
        percentageCost = (100 - (sellingFeePercentage + transactionFeePercentage)) / 100;

    } else if (targetMarginPercentage !== null && targetMarginPercentage !== 0) {

        totalCost = (itemCostAmount + shippingCostAmount + sellingFeeFixedAmount + transactionFeeFixedAmount);
        percentageCost = (100 - (sellingFeePercentage + transactionFeePercentage + targetMarginPercentage)) / 100;

        margin = targetMarginPercentage;
        let pointValueTargetMarginPercentage = targetMarginPercentage / 100;
        markup = Math.floor((pointValueTargetMarginPercentage / (1 - pointValueTargetMarginPercentage)) * 100);

    } else if (targetMarkupPercentage !== null && targetMarkupPercentage !== 0) {


        let pointValueTargetMarkupPercentage = targetMarkupPercentage / 100;
        targetMarginPercentage = ((pointValueTargetMarkupPercentage / (1 + pointValueTargetMarkupPercentage)) * 100);
        totalCost = (itemCostAmount + shippingCostAmount + sellingFeeFixedAmount + transactionFeeFixedAmount);
        percentageCost = (100 - (sellingFeePercentage + transactionFeePercentage + targetMarginPercentage)) / 100;

        markup = targetMarkupPercentage;
        margin = Math.floor(((pointValueTargetMarkupPercentage / (1 + pointValueTargetMarkupPercentage)) * 100));

    } else {
        throw new Error("Please specify a target profit, margin, or markup.");
    };

    if (!itemCostAmount || !itemCostAmount > 0) {
        return;
    };

    if (
        itemCostAmount < 0 || sellingFeePercentage < 0 ||
        sellingFeeFixedAmount < 0 || transactionFeePercentage < 0 ||
        transactionFeeFixedAmount < 0) {
        return;
    };

    let sellingPrice = (totalCost / percentageCost) - shippingChargeAmount;

    revenue = sellingPrice + shippingChargeAmount;
    sellingCost = ((revenue * sellingFeePercentage / 100) + sellingFeeFixedAmount);
    transactionCost = ((revenue * transactionFeePercentage / 100) + transactionFeeFixedAmount);
    shippingCost = shippingCostAmount;
    cost = itemCostAmount + sellingCost + transactionCost + shippingCost;
    profit = revenue - cost;

    if (targetProfitAmount !== null && targetProfitAmount !== 0) {
        margin = Math.floor((profit / revenue) * 100);
        let pointValuOfMargin = margin / 100;
        markup = Math.ceil((pointValuOfMargin / (1 - pointValuOfMargin) * 100));
    };

    const response = {
        answer: { sellingPrice: 0 },
        summaryOfValues: {
            itemCost: 0,
            shippingCost: 0,
            sellingCost: 0,
            transactionCost: 0,
            cost: 0,
            revenue: 0,
            profit: 0,
            margin: 0,
            markup: 0,
        },
        invoiceForBuyer: {
            itemPrice: 0,
            shipping: 0,
            subTotal: 0,
            total: 0,
        }
    };
    response['answer']['sellingPrice'] = sellingPrice.toFixed(2);

    response['summaryOfValues']['itemCost'] = itemCostAmount;
    response['summaryOfValues']['shippingCost'] = shippingCost;
    response['summaryOfValues']['sellingCost'] = sellingCost.toFixed(2);
    response['summaryOfValues']['transactionCost'] = transactionCost.toFixed(2);
    response['summaryOfValues']['cost'] = cost.toFixed(2);

    response['summaryOfValues']['revenue'] = revenue.toFixed(2);
    response['summaryOfValues']['profit'] = profit.toFixed(2);
    response['summaryOfValues']['margin'] = margin;
    response['summaryOfValues']['markup'] = markup;

    response['invoiceForBuyer']['itemPrice'] = sellingPrice.toFixed(2);
    response['invoiceForBuyer']['shipping'] = shippingChargeAmount;
    response['invoiceForBuyer']['subTotal'] = (sellingPrice + shippingChargeAmount).toFixed(2);
    response['invoiceForBuyer']['total'] = response['invoiceForBuyer']['subTotal'];

    return response;
};


