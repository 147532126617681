import React, { useEffect, useState } from 'react'
import Banner from '../Banner/Banner';
import { ListGroup, Offcanvas } from 'react-bootstrap';
import Images from "../../assets/proposal.JPG";
import { FaFilter } from 'react-icons/fa';
import { CircularProgress, Pagination } from '@mui/material';
import CardComponent from '../CardComponent/CardComponent';
import { Image_URL } from '../../Constants/host';
import "./ProposalNew.scss"
import { GetProposalCategoryList, ProposalByCategoryApi } from '../../Constants/Api/Api';
import Signin from '../../Pages/Signin/Signin';
import usePagination from '../../Pagination';
const ProposalNew = () => {
    const [blog, setBlog] = useState();
  

    const [category, setCategory] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [selectindex, setSelectIndex] = useState("all");
    const [title, setTitle] = useState("All Proposal");
    const [img, setImage] = useState("")
    const [Data, setData] = useState([]);

    const [data_count, setCount] = useState();
    let [page, setPage] = useState(1);
    const PER_PAGE = 6;
    const count = Math.ceil(data_count / PER_PAGE);
    const _DATA = usePagination(Data, PER_PAGE);
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };
    const handleCategoryClick = (index, title, slug, img) => {
        setImage(img)
        setTitle(title)
        setSelectedCategory(slug);
        setSelectIndex(index)
    };

    useEffect(() => {
        ProposalByCategoryApi(selectedCategory, PER_PAGE, page)
            .then((res) => {
                setBlog(res?.data?.data?.rows);
                setCount(res?.data?.data?.count)
                // setDataCount(res.data?.data?.length || 0);
            })
            .catch((error) => {
                console.log(error)
            });
    }, [selectedCategory, page, PER_PAGE]);
    
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        GetProposalCategoryList()
            .then((res) => {
                setCategory(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    useEffect(() => {
        setIsClient(true);
    }, []);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [offcanvasWidth, setOffcanvasWidth] = useState("");
    useEffect(() => {
        if (typeof window !== "undefined") {
            const width = window.innerWidth >= 1200 ? "50%" : "80%";
            setOffcanvasWidth(width);
        }
    }, []);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    const isToken = localStorage.getItem("UserLoginTokenApt")

    return (
        <>
            {!isToken && isToken == null ? <Signin /> :
                <div className='ProposalNew'>
                    <Banner CalculatorName={title} CalculatorImage={selectedCategory === "all" ? Images : Image_URL + img} />
                    <br />
                    <div>
                        <div className="filter_icon" onClick={handleShow}>
                            <FaFilter size={30} />Filter
                        </div>
                        <Offcanvas show={show} onHide={handleClose} style={{ width: offcanvasWidth }}>
                            <Offcanvas.Body style={{ padding: "0px" }}>
                                <ListGroup className="category_list">
                                    <h4 className="categoryText">Categories</h4>
                                    <ListGroup.Item
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className="category_item"
                                        onClick={() => { handleCategoryClick("All Proposal", "all"); handleClose() }}
                                    >
                                        All Category
                                    </ListGroup.Item>
                                    {category.map((item, index) => {
                                        return (
                                            <ListGroup.Item
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className="category_item"
                                                // className={`${styles.category_item} ${selectedCategory === index ? styles.selectedCategory : ''}`}
                                                key={index}
                                                onClick={() => { handleCategoryClick(item.title, item.slug, item.file_name); handleClose() }}
                                            >
                                                {item.title}
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </Offcanvas.Body>
                        </Offcanvas>
                        <div className="proposal_container">
                            <div className="sidebar">
                                <h4 className="categoryText">Categories</h4>
                                <ListGroup className="category_list">
                                    <ListGroup.Item
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className={`category_item ${selectindex === "all" ? "selectedCategory" : ""}`}
                                        onClick={() => handleCategoryClick("all", "All Proposal", "all",)}
                                    >
                                        All Category
                                    </ListGroup.Item>
                                    {category?.map((item, index) => {
                                        return (
                                            <ListGroup.Item
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                key={index}
                                                className={` category_item ${selectindex === index ? "selectedCategory" : ""}`}
                                                onClick={() => handleCategoryClick(index, item.title, item.slug, item.file_name)}
                                            >
                                                {item.title}
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </div>
                            <div className="content">
                                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-sm-1 g-4">
                                    {isClient && blog === undefined ? (
                                        <div
                                            className="loader-container"
                                            style={{ width: "100%", textAlign: "center", height: "1000px" }}
                                        >
                                            <div className="loader-content">
                                                <CircularProgress />
                                                <p>Fetching Proposals...</p>
                                            </div>
                                        </div>
                                    ) : isClient && blog.length === 0 ?
                                        <div
                                            style={{ width: "100%", textAlign: "center", height: "1000px" }}
                                        >
                                            <div className="loader-content">
                                                <p>No Proposals Available.... </p>
                                            </div>
                                        </div> :
                                        isClient && (
                                            blog?.map((item, index) => {
                                                return (
                                                    <div
                                                        className="col cardContainer"
                                                        key={index}
                                                    >
                                                        <div>
                                                            <CardComponent

                                                                data={item}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        )
                                    }
                                </div>
                                <div className="pagination_custom">
                                    <Pagination
                                        count={count}
                                        size="large"
                                        page={page}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}
export default ProposalNew
