import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap'
import "./AdvancedPrice.scss"
import DescriptionAlerts from '../../../Constants/alert/alert';
import { useLocation, useNavigate } from 'react-router-dom';
function SoftSkillPricing({ handleCloseBH }) {
    const firstSelectValue = 'Soft Skill';
    const [secondSelectValue, setSecondSelectValue] = useState('');
    const [showParticipants, setShowParticipants] = useState(false);
    const [participantsValue, setParticipantsValue] = useState('');
    const [aupValue, setAduValue] = useState('');
    const [customParticipants, setCustomParticipants] = useState('');
    const [customAdu, setCustomAdu] = useState();
    const [Per_No_Discount, setPer_No_Discount] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [travel_cost, setTravelCost] = useState("")
    const [dataStored, setDataStored] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
        text: "",
        icon: "",
    });
    const navigate = useNavigate()
    const location = useLocation()
    let slug = new URLSearchParams(location?.search).get("s");
    const levelsData = {
        'Soft Skill': {
            'Level 1': { participants: '1-15', aup: '$100-$140' },
            'Level 2': { participants: '16-30', aup: '$90-$99' },
            'Level 3': { participants: '31-50', aup: '$80-$89' },
            'Level 4': { participants: '51-75', aup: '$70-$79' },
            'Level 5': { participants: '76-100', aup: '$60-$69' },
            'Level 6': { participants: '100+', aup: '$50-$59' },
        },
    };

    const handleSecondSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (firstSelectValue && levelsData[firstSelectValue] && levelsData[firstSelectValue][selectedValue]) {
            setSecondSelectValue(selectedValue);
            const selectedLevel = levelsData[firstSelectValue][selectedValue];
            setParticipantsValue(selectedLevel.participants);
            setAduValue(selectedLevel.aup);
            setShowParticipants(true);
        } else {
            setShowParticipants(false);
            setParticipantsValue('');
            setAduValue('');
        }
    };
    const handleCustomParticipantsChange = (e) => {
        const value = e.target.value;
        setCustomParticipants(value);
        calculateResult(value, customAdu, travel_cost);
    };

    const handleCustomAduChange = (e) => {
        const value = e.target.value;
        setCustomAdu(value);
        calculateResult(customParticipants, value, travel_cost);
    };

    const handleCustomTravelCostChange = (e) => {
        const value = e.target.value;
        setTravelCost(value);
        calculateResult(customParticipants, customAdu, value);
    };

    const handleDiscountChange = (e) => {
        const value = e.target.value;
        setDiscountValue(value);
        calculateDiscount(value);
    };
    const calculateResult = (participants, aup, travel_cost) => {
        if (participants && aup && travel_cost !== undefined) {
            const result = parseInt(participants) * parseFloat(aup);
            const finalResult = result + (+travel_cost);
            setPer_No_Discount(finalResult);
            calculateDiscount(discountValue);
        } else {
            setPer_No_Discount('');
            setDiscountAmount('');
        }
    };

    const calculateDiscount = (discount) => {
        if (Per_No_Discount && discount) {
            const discountAmount = (Per_No_Discount * discount)
            setDiscountAmount(discountAmount);
        } else {
            setDiscountAmount('');
        }
    };


    const handleStoreData = () => {
        // const data = {
        //     heading: "SoftSkillPricing",
        //     Custom_Participants: customParticipants,
        //     Custom_Aup: customAdu,
        //     Travel_Cost: travel_cost,
        //     Per_No_Discount: Per_No_Discount,
        //     Annual_No_Discount: (Per_No_Discount * 12).toFixed(2),
        //     Discount_Value: (discountValue * 100).toFixed(2),
        //     Discount_Amount: discountAmount,
        //     After_Discount: ((Per_No_Discount - discountAmount).toFixed(2)),
        //     Annual_After_Discount: ((Per_No_Discount - discountAmount) * 12).toFixed(2),
        //     Workbook_Print_Cost: (customParticipants * 19).toFixed(2),
        //     One_Time_Cost: (((customParticipants * 19) / Per_No_Discount) * 100).toFixed(2),
        //     Annual_CPI_3: ((Per_No_Discount * 12) * 0.03).toFixed(2),
        //     Annual_CPI_5: ((Per_No_Discount * 12) * 0.05).toFixed(2)
        // };
        const data = {
            heading: "SoftSkillPricing",
            Custom_Participants: customParticipants,
            Custom_Aup: customAdu,
            Travel_Cost: travel_cost,
            Per_No_Discount: Per_No_Discount,
            Annual_No_Discount: (Per_No_Discount * 12).toFixed(2),
        };

        if (discountValue !== "no") {
            data.Discount_Value = (discountValue * 100).toFixed(2);
            data.Discount_Amount = discountAmount;
            data.After_Discount = ((Per_No_Discount - discountAmount).toFixed(2));
            data.Annual_After_Discount = ((Per_No_Discount - discountAmount) * 12).toFixed(2);
        }

        data.Workbook_Print_Cost = (customParticipants * 19).toFixed(2);
        data.One_Time_Cost = (((customParticipants * 19) / Per_No_Discount) * 100).toFixed(2);
        data.Annual_CPI_3 = ((Per_No_Discount * 12) * 0.03).toFixed(2);
        data.Annual_CPI_5 = ((Per_No_Discount * 12) * 0.05).toFixed(2);

        localStorage.removeItem("Calculation");
        localStorage.setItem('Calculation', JSON.stringify(data));
        // handleCloseBH()
        setAlertConfig({
            text: "Data Stored For Proposal.",
            icon: "success",
        });
        setAlert(true);

        setTimeout(() => {
            if (!slug) {

                navigate("/create/leadership-workshop-proposal");
            }
        }, 2000);
    }
    const { pathname } = useLocation()
console.log(customAdu,"customAdu")
    return (
        <div className="AdvancedPrice">
            {alert ? (
                <DescriptionAlerts text={alertConfig.text} icon={alertConfig.icon} />
            ) : null}
            <div className='Calculator_container'>
                <div className='Row_1'>
                    <div className='Col_1' style={{ padding: "4px" }} >
                        <div className='blank_input' style={{ width: "inherit", padding: "7px" }}>Soft Skill Pricing Model</div>
                    </div>
                    <div className='Col_1'>
                        <Form.Select value={secondSelectValue} onChange={handleSecondSelectChange} style={{ padding: "9px" }} >
                            <option value="">select--</option>
                            {levelsData[firstSelectValue]
                                ? Object.keys(levelsData[firstSelectValue]).map((level, index) => (
                                    <>
                                        <option key={index} value={level}>
                                            {level}
                                        </option>
                                    </>
                                ))
                                : null}
                        </Form.Select>
                    </div>
                </div>
                {showParticipants && (<>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                            <div className='Col_12'>
                                <div className='blank_input'>{participantsValue}</div>
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Participants'
                                    value={customParticipants}
                                    onChange={handleCustomParticipantsChange} /></div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>AUP:
                            <div className='Col_12'>
                                <div className='blank_input'>{aupValue}</div>
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Aup
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Aup'
                                    value={customAdu}
                                    onChange={handleCustomAduChange} /></div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Enter Travel Cost
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Travel Cost'
                                    value={travel_cost}
                                    onChange={handleCustomTravelCostChange} /></div>
                        </div>
                    </div>
                </>
                )
                }
                {Per_No_Discount && (<>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Fee (Month or Session)  :
                            <div className='Col_12 blank_input'>${(Per_No_Discount).toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual Fee :
                            <div className='Col_12 blank_input'>${(Per_No_Discount * 12).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </>
                )}
                {showParticipants &&
                    <Form.Select value={discountValue} onChange={handleDiscountChange} style={{ padding: "9px" }} >
                        <option value="">Select discount</option>
                        <option value="no">No discount</option>
                        <option value={0.02}>2%</option>
                        <option value={0.05}>5%</option>
                        <option value={0.1}>10%</option>
                        <option value={0.12}>12%</option>
                        <option value={0.15}>15%</option>
                        <option value={0.20}>20%</option>
                        <option value={0.25}>25%</option>
                        <option value={0.30}>30%</option>
                    </Form.Select>}


                {Per_No_Discount && discountValue !== "no" && discountAmount && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Discount Amount :
                            <div className='Col_12 blank_input'>${discountAmount}
                            </div>
                        </div>
                    </div>
                )}
                {Per_No_Discount && discountValue !== "no" && discountAmount && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Fee (Month or Session) After Discount :
                            <div className='Col_12 blank_input'>${(Per_No_Discount - discountAmount).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {Per_No_Discount && discountValue !== "no" && discountAmount && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual After Discount :
                            <div className='Col_12 blank_input'>${((Per_No_Discount - discountAmount) * 12).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {Per_No_Discount && discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Workbook Print Cost (Fedex) $19  :
                            <div className='Col_12 blank_input'>${(customParticipants * 19).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {Per_No_Discount && discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Print as a % of one time cost :
                            <div className='Col_12 blank_input'>{(((customParticipants * 19) / Per_No_Discount) * 100).toFixed(2)}%
                            </div>
                        </div>
                    </div>
                )}
                {Per_No_Discount && discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual CPI 3% :
                            <div className='Col_12 blank_input'>${((Per_No_Discount * 12) * 0.03).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                {Per_No_Discount && discountValue && (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Annual CPI 5%:
                            <div className='Col_12 blank_input'>${((Per_No_Discount * 12) * 0.05).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Button className="button_theme"
                onClick={() => { handleStoreData(); { pathname.split("/")[2] === "leadership-workshop-proposal" && handleCloseBH() } }}>Add to proposal
            </Button>

        </div>
    );
}
export default SoftSkillPricing;
