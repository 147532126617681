import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { priceCalculator } from "../../../CalculatorFunction/PriceCalculatorFunction"
import { CalculatorViewApi } from '../../../Constants/Api/Api'
const PriceCalculatorComponent = ({ calculatordetails }) => {
  const [cost, setCost] = useState()
  const [grossMargin, setGrossMargin] = useState()
  const [result, setResult] = useState()
  const handleSubmit = async () => {
    if (cost <= 0 || grossMargin <= 0) {
      setResult("nagative");
      return;
    }
    try {
      let data = await priceCalculator(cost, grossMargin)
      setResult(data);
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])
  const storedValue = localStorage.getItem("UserLoginTokenApt");

  const handleView = (id) => {
    CalculatorViewApi(id,storedValue).then((res) => {
    }).catch((error) => console.log(error, "error"))
  }
  return (
    <div>
      <Form>
        <Form.Group className='mb-2' >
          <span className='add_placeholder'>$</span>
          <Form.Control placeholder="Enter Cost" value={cost} onChange={(e) => { setCost(e.target.value) }} />
        </Form.Group>
        <Form.Group className='mb-2' >
          <span className='add_placeholder'>%</span>
          <Form.Control placeholder="Enter Gross Margin " value={grossMargin} onChange={(e) => { setGrossMargin(e.target.value) }} />
          <p className="subvalues">(Enter the value (0-99) )</p>
        </Form.Group>
      </Form>
      <div className='Button_style' >
        <Button className="button_theme" onClick={() => { setCost(""); setGrossMargin("") }}>Clear</Button>
        <Button className="button_theme" onClick={() => { handleSubmit(); handleView(calculatordetails?.calculatordetails?.id) }}>Calculate
        </Button>
      </div>
      {result &&
        <div className='Answer_Container'>
          <h5>Answer</h5>
          {result === "nagative" ?
            <p className='red'>
              Enter Cost & Revenue {`>`} 0
            </p>
            : <>
              <p>Price* (Revenue R) : ${result?.R} </p>
              <p>
                Profit (P) : ${result?.P} </p>
              <p>
                Mark Up (M) : {result?.M}%</p>
            </>}
        </div>
      }
    </div>
  )
}
export default PriceCalculatorComponent
