import React, { useEffect, useState } from 'react'
import "./Calculator.scss"
import { Container, Row } from 'react-bootstrap'
import Banner from '../Banner/Banner'
import { useLocation } from 'react-router-dom'
import { GetCalculatorDescription } from '../../Constants/Api/Api'
import { Image_URL } from '../../Constants/host'
import Signin from '../../Pages/Signin/Signin'
const Calculator = ({ children }) => {
    const [calculatordetails, setCalculatorDetails] = useState()
    const { pathname } = useLocation()
    const category_slug = pathname.split("/")[2]
    useEffect(() => {
        GetCalculatorDescription(category_slug).then((res) => {
            setCalculatorDetails(res?.data?.data)
        }).catch((e) => {
            console.log(e, "error")
        })
    }, [category_slug])
    const isToken = localStorage.getItem("UserLoginTokenApt")
    const UserStatus = localStorage.getItem("UserStatus")
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className='CalculatorSCss'>
            {(!isToken && isToken == null) || UserStatus === "DEACTIVATE" ? <Signin /> :
                <div className='calculator_main_container'>
                    <Banner CalculatorDesc={calculatordetails?.description} CalculatorName={calculatordetails?.calculator_name === undefined ? category_slug.replace(/-/g, " ") : calculatordetails?.calculator_name} CalculatorImage={Image_URL + calculatordetails?.file_name} />
                    <Row className='cal_main'>
                        <div className='Calculator_container'>
                            {React.Children.map(children, child => {
                                return React.cloneElement(child, { calculatordetails });
                            })}
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: calculatordetails?.profit_margin_formula }} className='description'></p>
                    </Row>
                </div>}
        </div>
    )
}
export default Calculator
