import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import Banner from '../Banner/Banner'
import "./Home.scss"
import { GetHomePageContent } from '../../Constants/Api/Api'
import { Image_URL } from '../../Constants/host'
import { Link } from 'react-router-dom'
import BammerVideo from "../../assets/aptvideo.mp4";
import HomeBanner from '../HomeBanner/HomeBanner'
const Home = () => {
  const [content, setContent] = useState()
  useEffect(() => {
    GetHomePageContent().then((res) => {
      setContent(res.data.data)
    })
      .catch((e) => {
        console.log(e, "error")
      })
  }, [])
  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  },[])

  return (
    <div className="Home">
  
      <HomeBanner/>
      <div className='Home_inner'>
        <h5 className="h5">Features</h5>
        <h4 className="h4">Why APT?</h4>
        {content?.map((item, index) => {
          return (
            index % 2 !== 0 ?
              <div className="Section-1" key={index}>
                <div className="HomeRight">
                  <div className="content">
                    <div className='heading'>
                      <h1 className="h1">{index + 1}</h1>&nbsp;
                      <h1 className='title'>{item.title}
                      </h1>
                    </div>
                    <p dangerouslySetInnerHTML={{
                      __html: item.description
                    }}></p>
                    <Button className="btn"><Link to={`${item.redirection_url}`} className='link'>
                      Get Started
                    </Link>
                    </Button>
                  </div>
                </div>
                <div className="Homeleft">
                  <img
                    src={`${Image_URL}${item?.file_name}`}
                    className="image image1"
                    loading="lazy"
                    alt="icon"
                  />
                </div>
              </div>
              : <div className="Section-1 reverse">
                <div className="Homeleft">
                  <img
                    src={`${Image_URL}${item?.file_name}`}
                    className="image image2"
                    loading="lazy"
                    alt="icon"
                  />
                </div>
                <div className="HomeRight">
                  <div className="content">
                    <div className='heading'>
                      <h1 className="h1">{index + 1}</h1>&nbsp;
                      <h1 className='title'>{item.title}
                      </h1>
                    </div>
                    <p dangerouslySetInnerHTML={{
                      __html: item.description
                    }}></p>
                    <Button className="btn"><Link to={`${item.redirection_url}`} className='link'>
                      Get Started
                    </Link>
                    </Button>
                  </div>
                </div>
              </div>
          )
        })}
      </div>
    </div>
  )
}
export default Home
