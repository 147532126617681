import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import "./PartnershipDiscounts.scss"
import { CalculatorViewApi } from '../../../Constants/Api/Api';
const PartnershipDiscounts = (calculatordetails) => {
    const [firstSelectValue, setFirstSelectValue] = useState(5000);
    const handleFirstSelectChange = (e) => {
        setFirstSelectValue(e.target.value);
    };
    const [secondSelectValue, setSecondSelectValue] = useState(0);
    const handleSecondSelectChange = (e) => {
        setSecondSelectValue(e.target.value);
    };
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    },[])
    const storedValue = localStorage.getItem("UserLoginTokenApt");

    const handleView = () => {
        CalculatorViewApi(calculatordetails?.calculatordetails?.id,storedValue).then((res) => {
        }).catch((error) => console.log(error, "error"))
      }
    return (
       
            <div className="PartnershipDiscounts">
              
               
                    <div className='Row_1' >
                        <div className='Col_main'>
                        <Form.Group  className='mb-2' >
                            <Form.Label>Customer commitment Level (By Annual Dollars):</Form.Label>
                            <Form.Select value={firstSelectValue} onChange={handleFirstSelectChange}>
                                <option value="5000 "> $5,000 </option>
                                <option value="10000"> $10,000  </option>
                                <option value="15000"> $15,000  </option>
                                <option value="20000"> $20,000  </option>
                                <option value="25000"> $25,000  </option>
                                <option value="30000"> $30,000  </option>
                                <option value="35000"> $35,000  </option>
                                <option value="40000"> $40,000  </option>
                                <option value="45000"> $45,000  </option>
                                <option value="50000"> $50,000  </option>
                                <option value="100000"> $100,000+</option>
                            </Form.Select>
                        </Form.Group>

                        </div>
                        <div className='Col_main'>
                        <Form.Group className='mb-2' >
                            <Form.Label>Partnerhip Discount Level %:</Form.Label>
                            
                            <Form.Select value={secondSelectValue} onChange={handleSecondSelectChange} onClick={handleView}>
                                <option value={0}> 0% </option>
                                <option value={0.02}>  2%</option>
                                <option value={0.05}>  5% </option>
                                <option value={0.1}> 10%  </option>
                                <option value={0.2}>  20% </option>
                            </Form.Select>
                        </Form.Group>

                        </div>

                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                        Dollar Value :
                            <div className='Col_12 blank_input'>${(firstSelectValue * secondSelectValue).toFixed(2)}
                            </div>
                        </div>
                    </div>
                
                </div>
              
      
    )
}
export default PartnershipDiscounts
