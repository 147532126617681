import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import "./signup.scss"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Input, message } from 'antd'
import { UserRegisterAPI } from '../../Constants/Api/Api'
// import { UserRegisterAPI } from '../../Constants/Api/Api'
import Image from "../../assets/Automated_Pricing_Calc.jpg"
const SignUp = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [form] = Form.useForm();

    const { email } = location.state || {};
    const onFinish = async (values) => {
        let formData = {
            name: values.username,
            email: email,
            mobile: values.usermobile,
            password: values.password,
            confirm_password: values.confirm
        };

        try {
            // setLoading(true);
            const res = await UserRegisterAPI(formData);
            message.success(res?.data?.message);
            // Navigate to /verifyotp and pass email and password as state
            navigate("/signin");
        } catch (error) {
            message.error(error?.response?.data?.message);
            console.log(error, "error")
        } finally {
            // setLoading(false);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo, "errorInfo")
    };
    useEffect(() => {
        form.setFieldValue(
            "useremail",
            email
        );
    }, [])
    // console.log(email, "email")
    return (
        <Container className="Signup">
            <div className="Main" >
            <div className="Left">
            <img src={Image} width="100%" alt="logo" />
          </div>
                <div className="Right">
                    <div className="form_inner">
                        <h1>Sign up</h1>
                        {/* <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="User Name"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="User Email"
                                name="useremail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Bussiness Name"
                                name="bussiness_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your bussiness name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="User Mobile"
                                name="usermobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your usermobile!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                            >
                                <Button htmlType="submit" className="button_theme" >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form> */}
                        <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="User Email"
                                name="useremail"

                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },

                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="Business Name"
                                name="bussiness_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your business name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="User Mobile"
                                name="usermobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your usermobile!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                            >
                                <Button type="primary" htmlType="submit" className="button_theme" >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                        {/* <Button className="button_theme">Continue</Button> */}
                    </div>
                    <p className="buttom_text">Already have an account? <Link to="">
                        Sign in
                    </Link>
                    </p>
                </div>
            </div>
        </Container>
    )
}
export default SignUp
