import React, { useEffect } from 'react'
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import "./VerifyOTP.scss"
import { Container } from 'react-bootstrap';
import Image from "../../assets/Automated_Pricing_Calc.jpg"
import { SendOTPAPI, VerifyOtpAPI } from '../../Constants/Api/Api';
const VerifyOTP = () => {
  const [form] = Form.useForm();
  // const { email } = useUser();
  const navigate = useNavigate()
  const location = useLocation();
  const { email } = location.state || {};
  const onFinish = (values) => {
    // let formData = {
    //   email: email,
    //   otp: values.userotp,
    //   type: "email_varification"
    //   //type:forgot_password
    // }
    VerifyOtpAPI(email, values.userotp).then((res) => {
      message.success(res?.data?.message)
      navigate("/sign-up", {
        state: {
          email: email,
        }
      });
    }).catch((error) => {
      if (error.response.data.message) {

        message.error(error.response.data.message)
      }
      else {
        message.error("Please Try Again")

      }
    })
  };
  const onFinishFailed = (errorInfo) => {
  };

  const handleResendOtp = () => {

    SendOTPAPI(email).then((res) => {
      message.success(res?.data?.message)

    }).catch((error) => {
      console.log(error, "error")
    })
  }
  return (

    <Container className="VerifyOtp main-container">
      <div className="Main" >
        <div className="Left">
          <img src={Image} width="100%" alt="logo" />
        </div>
        <div className="Right">
          <div className="form_inner">

            <Form
              name="basic"
              layout="vertical"

              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <h6>OTP has been sent to your <span style={{ fontWeight: "bolder" }}>{email}</span></h6>
              <Form.Item
                label="Enter OTP"
                name="userotp"
                rules={[
                  {
                    required: true,
                    // message: 'Please input your usermobile!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item

              >
                <p
                  onClick={handleResendOtp}
                  className="forgotpasswordtext"
                >
                  Resend Otp
                </p>
                <Button htmlType="submit" className="button_theme">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>

        </div>
      </div>
    </Container>
  )
}
export default VerifyOTP
