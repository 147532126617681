import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Url } from "../../Constants/host";
import { FaFileDownload } from "react-icons/fa";
import { Button } from "react-bootstrap";
import "./Download.scss"
import Signin from "../../Pages/Signin/Signin";
const DocIframe = () => {
  const location = useLocation()
  const { data } = location.state;
  const [loading, setLoading] = useState(true);
  const [docSource, setDocSource] = useState('');
  

  useEffect(() => {
    // Simulating fetching the document URL (replace with actual fetching logic)
    // Here, setting a timeout to simulate asynchronous fetching
    setTimeout(() => {
      const fetchedDocSource = `${Url}${data.file_type}/${data.file_name}`;
      setDocSource(fetchedDocSource);
      setLoading(false);
    }, 2000); // Simulating a delay of 2 seconds
  }, [data]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = docSource;
    link.download = data.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  },[])
  const isToken = localStorage.getItem("UserLoginTokenApt")
  const UserStatus = localStorage.getItem("UserStatus")

  return (
    <>
    {!isToken && isToken == null ? <Signin /> :
    <div className="Download">
      <div className="Download_inner">
        <h2>{data?.title}</h2>
        {UserStatus==="ACTIVATE"&&
        <Button className='button_theme'
          onClick={handleDownload}
        >
          Download Proposal
          <FaFileDownload size={30} />
        </Button>
}
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (<>
        <br/>
        <iframe
          src={"https://docs.google.com/viewer?url=" + docSource + "&embedded=true"}
          title="file"
          width="100%"
          height="600"
          onLoad={() => setLoading(false)} // Once the iframe loads, set loading to false
        ></iframe>
      </>
      )}
    </div>
}</>
  );
};

export default DocIframe;

